import React, { useState, useEffect, useRef } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import '../styles/SliderProducts.css'
import Slider from "react-slick";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/navigation';
import { useNavigate } from 'react-router-dom';

const Product = (props) => {
    const navigate = useNavigate();
    const handleGoToProduct = () => {
        navigate(`/${props.productInfo._id}`, { replace: true });
        window.location.reload(); // This forces the reload
      };

    return (
        <div className='home-slider-product' onClick={handleGoToProduct}>
            <div className='product-image'>
                <img src={props.productInfo.img} alt="Watch" />
            </div>
            <div className='product-discription'>
                <div className='product-header'>
                    <p className='product-header-name'>{props.productInfo.brand}</p>
                    <p className='product-header-thin-margin'>{props.productInfo.name}</p>
                </div>
                <p className='product-header-thin'>Reference Number: {props.productInfo.refNumber}</p>
                <div className='product-footer'>
                    <img className='product-logo' src="/img/untitled-40-27.png" alt="Lux And Co" />
                    <p>Price On Request</p>
                </div>
            </div>
        </div>
    )
}

const products = [
    {
        'name': 'Perpetual Calendar',
        'brand': 'Audemars Piguet',
        'refNumber': '26574TI.OO.1220TI.01',
        'img': '/img/group-55-1.png',
        '_id': process.env.REACT_APP_SLIDERPRODUCTID1
    },
    {
        'name': 'Sky Dweller',
        'brand': 'Rolex',
        'refNumber': '326934-0003',
        'img': '/img/group-56-1.png',
        '_id': process.env.REACT_APP_SLIDERPRODUCTID2
    },
    {
        'name': 'Aquanaut',
        'brand': 'Patek Phillipe',
        'refNumber': '5167R-001',
        'img': '/img/group-57-1.png',
        '_id': process.env.REACT_APP_SLIDERPRODUCTID3
    },
    {
        'name': 'RM72-01',
        'brand': 'Richard Mille',
        'refNumber': 'RM72-01',
        'img': '/img/RM-Product-Slider.png',
        '_id': process.env.REACT_APP_SLIDERPRODUCTID4
    },
    {
        'name': 'Sky Moon Tourbillon',
        'brand': 'Patek Phillipe',
        'refNumber': '6002R-001',
        'img': '/img/Sky-Moon-Patek.png',
        '_id': process.env.REACT_APP_SLIDERPRODUCTID5
    },
    {
        'name': 'Red Rafael Nadal',
        'brand': 'Richard Mille',
        'refNumber': 'RM35-02',
        'img': '/img/Other-RM-Slider.png',
        '_id': process.env.REACT_APP_SLIDERPRODUCTID6
    },
];

const SliderProjucts = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderRef = useRef(null);
    const sliderContentRef = useRef(null);
    const [maxScroll, setMaxScroll] = useState(0);
    const [isEnd, setIsEnd] = useState(false);
    const offset = 2; // Adjust based on your design (0-based index)

    const slidesToShow = 1; // Adjust based on your design
    const slidesToScroll = 5; // Adjust based on your design
    const totalSlides = products.length;

    const maxIndex = totalSlides - slidesToShow;

    const [clickCount, setClickCount] = useState(0); // Track number of clicks

    const handleAfterChange = (index) => {
        setCurrentIndex(index);
        setClickCount((prevCount) => prevCount + 1); // Increment click count

        // Check if the current slide is the last one
        if (index === products.length - 1) {
            setIsEnd(true); // Update state when at the end
        }
    };

    function SampleNextArrow(props) {
        const { currentIndex, maxIndex, onClick } = props;
        const isVisible = currentIndex < maxIndex;
        return isVisible ? (
            <div className="slider-arrow-right" onClick={onClick}>
                <img src="/img/group-24-4.png" alt="Next" />
            </div>
        ) : null;
    }


    function SamplePrevArrow(props) {
        const { currentIndex, onClick } = props;
        const isVisible = currentIndex > 0;
        return isVisible ? (
            <div className="slider-arrow-left" onClick={onClick}>
                <img src="/img/group-24-4.png" alt="Previous" />
            </div>
        ) : null;
    }
    

    const sliderOffsetStyle = {
        paddingRight: isEnd ? '20px' : '0',
    };
    
    const settings = {
        className: "slider variable-width",
        // dots: true,
        infinite: false,
        // centerMode: true,
        slidesToShow,
        slidesToScroll: 1,
        variableWidth: true,
        ref: sliderRef, // Attach the ref here
        beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
        afterChange: handleAfterChange,
        nextArrow: <SampleNextArrow currentIndex={currentIndex} maxIndex={maxIndex} />,
        prevArrow: <SamplePrevArrow currentIndex={currentIndex} />
      };

    return (
        <div className='productpage-container'>
            <div className='slider-container' ref={sliderRef}>
                {/* <Slider
                    {...settings}
                >
                    {products.map((product, index) => (
                        <Product key={index} productInfo={product} />
                    ))}
                </Slider> */}
                <SliderTest/>
            </div>
        </div>
    );
}

export default SliderProjucts;

const SliderTest = () => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    useEffect(() => {
        const swiperInstance = document.querySelector('.mySwiper').swiper;
        if (swiperInstance) {

            swiperInstance.params.navigation.prevEl = prevRef.current;
            swiperInstance.params.navigation.nextEl = nextRef.current;

            // Destroy and reinitialize navigation to avoid duplication
            swiperInstance.navigation.destroy();
            swiperInstance.navigation.init();
            swiperInstance.navigation.update();

            // Event listener for slide change
            swiperInstance.on('slideChange', () => {
                setIsBeginning(swiperInstance.isBeginning);
                setIsEnd(swiperInstance.isEnd);
            });

            // Event listener for reaching the end
            swiperInstance.on('reachEnd', () => {
                setIsEnd(true);
            });

            // Event listener for leaving the end or beginning
            swiperInstance.on('fromEdge', () => {
                setIsBeginning(swiperInstance.isBeginning);
                setIsEnd(swiperInstance.isEnd);
            });

            // Initial check for swiper state
            setIsBeginning(swiperInstance.isBeginning);
            setIsEnd(swiperInstance.isEnd);
        }
    }, []);

    return (
        <div>
            <div className="swiper-nav-buttons">
                <img 
                    ref={prevRef} 
                    src="/img/group-24-4.png" 
                    alt="Previous" 
                    className="slider-arrow-left" 
                    style={{
                        height: '60px', 
                        width: '60px', 
                        transform: 'rotate(180deg)', 
                        display: isBeginning ? 'none' : 'block'
                    }}
                />
                <img 
                    ref={nextRef} 
                    src="/img/group-24-4.png" 
                    alt="Next" 
                    className="slider-arrow-right" 
                    style={{
                        height: '60px', 
                        width: '60px', 
                        display: isEnd ? 'none' : 'block'
                    }}
                />
            </div>
            <Swiper
                watchSlidesProgress={true}
                spaceBetween={10}
                className="mySwiper"
                modules={[Navigation]}
                onInit={(swiper) => {
                    swiper.params.navigation.prevEl = prevRef.current;
                    swiper.params.navigation.nextEl = nextRef.current;
                    swiper.navigation.init();
                    swiper.navigation.update();

                    // Initial state check
                    setIsBeginning(swiper.isBeginning);
                    setIsEnd(swiper.isEnd);
                }}
                breakpoints={{
                    375: {
                        slidesPerView: 1.07,
                    },
                    790: {
                        slidesPerView: 1.1,
                    },
                    1000: {
                        slidesPerView: 1.48,
                    },
                    1350: {
                        slidesPerView: 2.65,
                    },
                    1700: {
                        slidesPerView: 2.65,
                    },
                    1800: {
                        slidesPerView: 3.75,
                    }
                }}
            >
                {products.map((product, index) => (
                    <SwiperSlide key={index} className='swiper-slider-product-container'>
                        <Product productInfo={product} className='swiper-slider-product' />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}