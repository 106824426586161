import React, { useState, useEffect } from 'react';
import AdminCreationMenu from './AdminCreationMenu'

const FullScreenAdminMenu = ({
    isFullScreen,
    setIsFullScreen,
    selectedCollection,
    setSelectedItem,
    selectedItem,
    getProducts,
    getBrands,
    getComplication,
    getSizes,
    getBrandModels,
    getFunctions,
    sizes,
    complications,
    brands,
    products,
    brandModels,
    functions,
    toast
}) => {

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
        setSelectedItem(null)
    };

    const buttonStyle = {
        backgroundColor: '#606060',
        color: '#ffffff',
        padding: '10px 20px',
        fontSize: '16px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'background-color 0.3s'
    };

    return (
        <div>
            {selectedCollection && (
                <button
                    onClick={toggleFullScreen}
                    style={{
                        ...buttonStyle
                    }}
                >
                    {isFullScreen ? 'Exit Full Screen' : 'Create'}
                </button>
            )}

            {isFullScreen && (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: isFullScreen ? 'fixed' : 'static',
                    top: 0,
                    left: 0,
                    width: isFullScreen ? '100vw' : 'auto',
                    height: isFullScreen ? '100vh' : 'auto',
                    backgroundColor: 'white',
                    zIndex: isFullScreen ? 1000 : 'auto',
                    overflow: 'auto',
                    flexGrow: 1,
                    overflowY: 'auto',
                    padding: '0 20px 20px 20px'
                }}>
                    <button 
                        onClick={toggleFullScreen}
                        style={{
                            ...buttonStyle,
                            position: 'absolute',
                            top: '35px',
                            right: '60px',
                        }}
                    >
                        Close
                    </button>
                    <div style={{minWidth:'500px', height:'200%'}}>
                        <AdminCreationMenu
                            formType={selectedCollection}
                            mode={selectedItem ? 'update' : 'create'}
                            setSelectedItem={setSelectedItem}
                            selectedItem={selectedItem}
                            selectedCollection={selectedCollection}
                            getProducts={getProducts}
                            getBrands={getBrands}
                            getComplication={getComplication}
                            getSizes={getSizes}
                            getBrandModels={getBrandModels}
                            getFunctions={getFunctions}
                            sizes={sizes}
                            complications={complications}
                            brands={brands}
                            products={products}
                            brandModels={brandModels}
                            functions={functions}
                            isFullScreen={isFullScreen}
                            setIsFullScreen={setIsFullScreen}
                            toast={toast}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default FullScreenAdminMenu;