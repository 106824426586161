import { useNavigate } from 'react-router-dom';
import "../styles/Footer.css"

const FooterDesktop = () => {
    const navigate = useNavigate();

    return (
        <div className="footer-container">
            <div className="footer-content">
                <div className="footer-section">
                    <h1 className="footer-section-header">ADDRESS</h1>
                    <p className="footer-section-info">
                        Level 12, 440 Collins St,
                        MELBOURNE VIC 3000
                        AUSTRALIA
                    </p>
                </div>
                <div className="footer-section">
                    <h1 className="footer-section-header">COLLECTIONS</h1>
                    <p className="footer-section-info" onClick={() => navigate(`/catalog?brand=${process.env.REACT_APP_BRAND_ROLEX_ID}`)}>
                        Rolex
                    </p>
                    <p className="footer-section-info" onClick={() => navigate(`/catalog?brand=${process.env.REACT_APP_BRAND_AP_ID}`)}>
                        Audemars Piguet
                    </p>
                    <p className="footer-section-info" onClick={() => navigate(`/catalog?brand=${process.env.REACT_APP_BRAND_PETEK_ID}`)}>
                        Patek Phillipe
                    </p>
                    <p className="footer-section-info" onClick={() => navigate(`/catalog?brand=${process.env.REACT_APP_BRAND_RM_ID}`)}>
                        Richard Mille
                    </p>
                    <p className="footer-section-info" onClick={() => navigate(`/catalog?brand=${process.env.REACT_APP_BRAND_OMEGA_ID}`)}>
                        Omega
                    </p>
                    <p className="footer-section-info" onClick={() => navigate(`/catalog?brand=${process.env.REACT_APP_BRAND_TUDOR_ID}`)}>
                        Tudor
                    </p>
                </div>
                <div className="footer-section">
                    <h1 className="footer-section-header">LEGAL & PRIVACY</h1>
                    <p className="footer-section-info">
                        Cookie Policy
                    </p>
                    <p className="footer-section-info">
                        Return Policy
                    </p>
                    <p className="footer-section-info">
                        Terms & Conditions
                    </p>
                </div>
                <div className="footer-section">
                    <h1 className="footer-section-header">CONTACT US</h1>
                    <p className="footer-section-info">
                        Call now +61 449 992 363
                    </p>
                    <p className="footer-section-info-email">
                        contact@luxandco.com
                    </p>
                </div>
                <div className="footer-section">
                    <h1 className="footer-section-header">FOLLOW US</h1>
                    <div className="footer-sections-images">
                        <img className="footer-section-image" src="/img/luxyoutube-2.png" alt="" onClick={() => window.open('https://www.youtube.com/@LuxandCoReviews', '_blank')}/>
                        <img className="footer-section-image" src="/img/luxig-2.png" alt="" onClick={() => window.open('https://www.instagram.com/luxandco/', '_blank')}/>
                        <img className="footer-section-image" src="/img/luxlinkedin-2.png" alt="" onClick={() => window.open('https://www.linkedin.com/feed/', '_blank')}/>
                    </div>
                </div>
                <div className="footer-section-outer">
                    <h1 className="footer-section-header">REACH US VIA WHATSAPP</h1>
                    <button className="footer-btn" onClick={() => window.open('https://wa.me/61449992363?text=', '_blank')}>WhatsApp <span>
                        <img src="/img/luxwhatsapp-2.png" alt="" />    
                    </span></button>
                </div>
            </div>
            <img className="footer-logo" src="/img/lux-footter-logo-2.png" alt="" />
        </div>
    )
}

const FooterMobile = () => {
    return (
        <div className="footer-container">
        <img className="footer-logo" src="/img/lux-footter-logo-2.png" alt="" />
        <div className="footer-content">
            <div className="footer-mobile-seperation">
            <div className="footer-section">
                <h1 className="footer-section-header">ADDRESS</h1>
                <p className="footer-section-info">
                    Level 12, 440 Collins St,
                    MELBOURNE VIC 3000
                    AUSTRALIA
                </p>
            </div>
            <div className="footer-section">
                <h1 className="footer-section-header">COLLECTIONS</h1>
                <p className="footer-section-info">
                    Rolex
                </p>
                <p className="footer-section-info">
                    Audemars Piguet
                </p>
                <p className="footer-section-info">
                    Patek Phillipe
                </p>
                <p className="footer-section-info">
                    Richard Mille
                </p>
                <p className="footer-section-info">
                    Omega
                </p>
                <p className="footer-section-info">
                    Tudor
                </p>
            </div>
            </div>
            <div className="footer-mobile-seperation">
                <div className="footer-section">
                    <h1 className="footer-section-header">LEGAL & PRIVACY</h1>
                    <p className="footer-section-info">
                        Cookie Policy
                    </p>
                    <p className="footer-section-info">
                        Return Policy
                    </p>
                    <p className="footer-section-info">
                        Terms & Conditions
                    </p>
                </div>
                <div className="footer-section">
                    <h1 className="footer-section-header">CONTACT US</h1>
                    <p className="footer-section-info">
                        Call now +61 449 992 363
                    </p>
                    <p className="footer-section-info-email">
                        contact@luxandco.com
                    </p>
                </div>
            </div>
            <div className="footer-mobile-seperation">
                <div className="footer-section">
                    <h1 className="footer-section-header">FOLLOW US</h1>
                    <div className="footer-sections-images">
                        <img className="footer-section-image" src="/img/luxyoutube-2.png" alt=""/>
                        <img className="footer-section-image" src="/img/luxig-2.png" alt=""/>
                        <img className="footer-section-image" src="/img/luxlinkedin-2.png" alt=""/>
                    </div>
                </div>
                <div className="footer-section-outer">
                    <h1 className="footer-section-header">WHATSAPP US</h1>
                    <button className="footer-btn">WhatsApp <span>
                        <img src="/img/luxwhatsapp-2.png" alt="" />    
                    </span></button>
                </div>
            </div>
        </div>
    </div>
    )
}

const Footer = () => {
    return (
        <div className="footer-container">
            {
                window.innerWidth > 1280?
                <FooterDesktop/>
                :
                <FooterMobile/>
            }
        </div>
    );
}
 
export default Footer;