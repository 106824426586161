import React, { useState } from 'react';
import DropDown from './DropDown';
import { useNavigate } from 'react-router-dom';
import "../styles/Header.css"

const Header = (params) => {
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    return (
        <div className="header-container" style={{backgroundColor:`${params.backgroundColor}`}}>
            <DropDown
                isOpen={isDropdownOpen}
                onClose={() => setIsDropdownOpen(false)}
            />
            <div className="header">
                <div className="header-left">
                    {
                        params.theme === 'light'? (
                            <img onClick={() => setIsDropdownOpen(true)} className="burgerBtn" src="/img/rectangle-56.png" alt=""/>
                        ) : (
                            <img onClick={() => setIsDropdownOpen(true)} className="burgerBtn" src="/img/burger.png" alt=""/>
                        )
                    }
                    <div className="desktop-options" style={{color:params.theme === 'light'?'#fff': '#000'}}>
                        <p onClick={() => navigate('/catalog')}>Watches</p>
                    </div>
                    <div className="desktop-options" style={{color:params.theme === 'light'?'#fff': '#000'}} onClick={() => navigate('/sellconsign',{ replace: true })}>
                        <p>Sell / Consign</p>
                    </div>
                </div>
                <div className="header-center">
                    {
                        params.theme === 'light'? (
                            <img src="/img/lux-co-logo-2.png" alt="" onClick={() => navigate('/')}/>
                        ): (
                            <img src="/img/lux-co-logo-99.png" alt="" onClick={() => navigate('/')}/>
                        )
                    }
                </div>
                <div className="header-right">
                    <div className="desktop-options" style={{color:params.theme === 'light'?'#fff': '#000'}}>
                        <a href='mailto:contact@luxandco.com' style={{color:params.theme === 'light'?'#fff': '#000'}}>Contact</a>
                    </div>
                    <div className="desktop-options" onClick={() => navigate('/about')} style={{color:params.theme === 'light'?'#fff': '#000'}} >
                        <p>About Us</p>
                    </div>
                    <div style={{height:'25px', width: '25px', zIndex:'1000'}} onClick={() => navigate('/catalog',{ replace: true })}>
                        {
                            params.theme === 'light'? (
                                <img className="searchBtn" src="/img/rectangle-59.png" alt="" onClick={() => navigate('/catalog',{ replace: true })} />
                            ) : (
                                <img className="searchBtn" src="/img/search.png" alt="" onClick={() => navigate('/catalog',{ replace: true })}/>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Header;