import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion"
import { useNavigate } from 'react-router-dom';
import Header from "./components/Header"
import Footer from './components/Footer'
import SliderProjucts from "./components/SliderProducts";
import SliderBrands from "./components/SliderBrands";
import TextAnimation from "./components/TextAnimation";
import "./styles/HomeComponent.css"
import "./Home-Desktop.css";
import "./Home-Mobile.css";

const customVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5, // Adjust the duration as needed
      ease: 'easeInOut'
    }
  }
};

const customVariants1 = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5, // Adjust the duration as needed
      ease: 'easeInOut'
    }
  }
};

const HomeHeader = () => {
  const navigate = useNavigate();

  return (
    <div className="homecomponent-container">
      <div className="homecomponent-content">
      <TextAnimation animationVariants={customVariants}>
        <h2 className="homeheader-subheader-text1">free</h2>  
      </TextAnimation>
      <TextAnimation animationVariants={customVariants1} delay={0.25}>
        <h1 className="homeheader-header-text2">WATCH VALUATION</h1>
      </TextAnimation>
        <TextAnimation animationVariants={customVariants1} delay={0.5}>
          <div className="homeheader-header-subtext">
            <p>Experience our streamlined service</p>
            <p>for selling luxury watches.</p>
          </div>
        </TextAnimation>
        <TextAnimation animationVariants={customVariants1} delay={0.75}>
          <div className="homeheader-btn">
            <div className="gifsection-subbtn" onClick={() => navigate('/sellconsign')}>
                <img className="gifsection-img" src="/img/untitled-40-37.png" alt=""/>
                <p className="homeheader-header-text-btn">Learn More</p>
              </div>
          </div>
        </TextAnimation>
      </div>
      {
        window.innerWidth >= 800?
        <video
        src="https://content.skibidirank.com/videos/BackgroundVideo.mp4"
        className="video-background-desktop"
        autoPlay 
        loop 
        muted 
        playsInline
      ></video>
      :
      <video
        src="https://content.skibidirank.com/videos/BackgroundVideoMobile.mp4"
        className="video-background-modile"
        autoPlay 
        loop 
        muted 
        playsInline
      ></video>
      }
    </div>
  )
}

const AdditionsSection = () => {
  return (
    <div className="additions-container">
      <div className="additions-header">
        <TextAnimation animationVariants={customVariants1} delay={0}>
          <h2 className="homeheader-subheader-text">new</h2>
        </TextAnimation>
        <TextAnimation animationVariants={customVariants1} delay={0.25}>
          <h1 className="homeheader-header-text">ADDITIONS</h1>
        </TextAnimation>
        <TextAnimation animationVariants={customVariants1} delay={0.5}>
          <div className="homeheader-header-subtext">
            <p>Discover the latest marvel in timekeeping<br/>technology: the ChronoMaster</p>
          </div>
        </TextAnimation>
      </div>
      <div className="additions-slider">
        <div className="slider-location-controler">
          <TextAnimation animationVariants={customVariants1} delay={0.5}>
            <SliderProjucts/>
          </TextAnimation>
        </div>
      </div>
    </div>
  )
}

const ImageSection = () => {
  // State to track if the elements are in view
  const [isInView, setIsInView] = useState({
    subheader: false,
    header: false,
    subtext: false
  });

  // Refs for the elements
  const subheaderRef = useRef(null);
  const headerRef = useRef(null);
  const subtextRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const handleIntersect = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (entry.target === subheaderRef.current) {
            setIsInView(prev => ({ ...prev, subheader: true }));
          } else if (entry.target === headerRef.current) {
            setIsInView(prev => ({ ...prev, header: true }));
          } else if (entry.target === subtextRef.current) {
            setIsInView(prev => ({ ...prev, subtext: true }));
          }
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersect, observerOptions);

    if (subheaderRef.current) observer.observe(subheaderRef.current);
    if (headerRef.current) observer.observe(headerRef.current);
    if (subtextRef.current) observer.observe(subtextRef.current);

    return () => {
      if (subheaderRef.current) observer.unobserve(subheaderRef.current);
      if (headerRef.current) observer.unobserve(headerRef.current);
      if (subtextRef.current) observer.unobserve(subtextRef.current);
    };
  }, []);

  return (
    <div className="whyshop-container">
      <div className="whyshop-content">
        <div className="whyshop-image-container">
          <TextAnimation animationVariants={customVariants1} delay={0}>
            <img src="/img/rectangle-189.png" alt="" />
          </TextAnimation>
        </div>
        <div className="whyshop-text-container">
          <TextAnimation animationVariants={customVariants1} delay={0}>
            <h2
              className={`homeheader-subheader-text`}
              ref={subheaderRef}
            >
              why shop
            </h2>
          </TextAnimation>
          <TextAnimation animationVariants={customVariants1} delay={0.25}>
            <h1
              className={`homeheader-header-text`}
              ref={headerRef}
            >
              AT LUX & CO?
            </h1>
          </TextAnimation>
          <TextAnimation animationVariants={customVariants1} delay={0.5}>
          <div
            className={`homeheader-header-subtext`}
            ref={subtextRef}
          >
            <p>Discover the latest marvel in timekeeping<br />technology: the ChronoMaster</p>
          </div>
          </TextAnimation>
          </div>
      </div>
    </div>
  );
};

const BrandSliderSection = () => {
  return (
    <div className="brandslider-container">
      <div className="brandslider-header">
          <TextAnimation animationVariants={customVariants1} delay={0}>
            <h2 className="homeheader-subheader-text">shop</h2>
          </TextAnimation>
          <TextAnimation animationVariants={customVariants1} delay={0.25}>
            <h1 className="homeheader-header-text">BY BRAND</h1>
          </TextAnimation>
          <TextAnimation animationVariants={customVariants1} delay={0.5}>
            <div className="homeheader-header-subtext">
              <p>Discover the latest marvel in timekeeping<br/>technology: the ChronoMaster</p>
            </div>
          </TextAnimation>
      </div>
      <div className="brandslider-slider">
        <TextAnimation animationVariants={customVariants1} delay={0}>
          <SliderBrands/>
        </TextAnimation>
      </div>
    </div>
  )
}

const FeaturedSection = () => {
  return (
    <div className="featured-container">
      <div className="featured-content">
          <TextAnimation animationVariants={customVariants1} delay={0}>
            <h2 className="homeheader-subheader-text">this month's</h2>
          </TextAnimation>
          <TextAnimation animationVariants={customVariants1} delay={0.25}>
            <h1 className="homeheader-header-text">FEATURED MODELS</h1>
          </TextAnimation>
      </div>
    </div>
  )
}

// <h2 className="homeheader-subheader-text">AP</h2>
// <h1 className="homeheader-header-text">ROYAL OAK</h1>

const GifSectionRight = (props) => {
  const navigate = useNavigate();

  const handleClick = () => {
      const queryParams = new URLSearchParams({
        model: props._id,
      }).toString();
  
      navigate(`/catalog?${queryParams}`);
  };

  return (
    <div className="gifsection-container" onClick={handleClick}>
        <div className="gifsection-content">
          <div className="gifsection-header-text-left">
              <TextAnimation animationVariants={customVariants1} delay={0}>
                  <h2 className="homeheader-subheader-text">{props.brand}</h2>
              </TextAnimation>
              <TextAnimation animationVariants={customVariants1} delay={0.25}>
              <h1 className="homeheader-header-text-long">{props.name}</h1>
              </TextAnimation>
              <TextAnimation animationVariants={customVariants1} delay={0.5}>
                <div className="gifsection-subbtn">
                  <img className="gifsection-img" src="/img/untitled-40-34.png" alt=""/>
                  <p className="homeheader-header-text-btn">Browse All Variations</p>
                </div>
              </TextAnimation>
          </div>
          <div className="gifsection-gif-container">
            <img src={props.gifUrl} alt="" />
          </div>
        </div>
    </div>
  )
}

const GifSectionLeft = (props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const queryParams = new URLSearchParams({
      model: props._id,
    }).toString();

    navigate(`/catalog?${queryParams}`);
  };


  return (
    <div className="gifsection-container" onClick={handleClick}>
      <div className="gifsection-content">
        <div className="gifsection-gif-container-left">
          <img src={props.gifUrl} alt="" />
        </div>
        <div className="gifsection-header-text-right">
          <TextAnimation animationVariants={customVariants1} delay={0}>
            <h2 className="homeheader-subheader-text">{props.brand}</h2>
          </TextAnimation>
          <TextAnimation animationVariants={customVariants1} delay={0.25}>
            <h1 className="homeheader-header-text-long">{props.name}</h1>
          </TextAnimation>
          <TextAnimation animationVariants={customVariants1} delay={0.5}>
            <div className="gifsection-subbtn">
              <img className="gifsection-img" src="/img/untitled-40-34.png" alt=""/>
              <p className="homeheader-header-text-btn">Browse All Variations</p>
            </div>
          </TextAnimation>
        </div>
      </div>
    </div>
  )
}

const BrowseSection = () => {
  const navigate = useNavigate();

  return (
    <div className="browsesection-container">
      <div className="browsesection-content">
            <TextAnimation animationVariants={customVariants1} delay={0}>
              <h1 className="homeheader-header-text1">BROWSE OUR<br/>FULL COLLECTION</h1>
            </TextAnimation>
            <TextAnimation animationVariants={customVariants1} delay={0.25}>
              <h2 className="homeheader-subheader-text">of luxury timepieces</h2>
            </TextAnimation>
            <TextAnimation animationVariants={customVariants1} delay={0.5}>
              <div style={{display:'flex',justifyContent:'center', alignItems:'center'}}>
                <img className="gifsection-img" src="/img/untitled-40-34.png" alt=""/>
                <p onClick={() => navigate('/catalog')}>Explore The Catalogue</p>
              </div>
            </TextAnimation>
      </div>
    </div>
  )
}

const HomePage = () => {
  return (
    <div>
      {/* <HomeDesktop/> */}
      <div className="header-controle-container">
        <Header theme='light'/>
      </div>
      <HomeHeader style={{position: 'reletive'}}/>
      <AdditionsSection/>
      <ImageSection/>
      <BrandSliderSection/>
      <FeaturedSection/>
      {
        window.innerWidth < 1100 ?
        <GifSectionLeft gifUrl='/img/apleft-1.gif' brand='ap' name='ROYAL OAK' _id={process.env.REACT_APP_ROTAL_OAK_ID}/>
        :
        <GifSectionRight gifUrl='/img/apright-1.gif' brand='ap' name='ROYAL OAK' _id={process.env.REACT_APP_ROTAL_OAK_ID}/>
      }
      <GifSectionLeft gifUrl='/img/image-6.gif' brand='patek phillipe' name='NAUTILUS' _id={process.env.REACT_APP_NAUTILUS_ID}/>
      {
        window.innerWidth < 1100 ? 
        <GifSectionLeft gifUrl='/img/Rolex-Left-GIF.gif' brand='rolex' name='DATEJUST' _id={process.env.REACT_APP_DATAJUST_ID}/>
        :
        <GifSectionRight gifUrl='/img/Rolex-Right-GIF.gif' brand='rolex' name='DATEJUST' _id={process.env.REACT_APP_DATAJUST_ID}/>
      }
      <BrowseSection/>
      <Footer />

    </div>
  );
}
 
export default HomePage;

const HomeDesktop = () => {
  return (
    <div style={{opacity:1, zIndex: 1000, position: 'absolute'}}>
    <div className="home-desktop">
      <div className="div">
        <div className="overlap">
          <div className="overlap-group">
            <div className="text-wrapper">of luxury timepieces</div>
            <img className="untitled" alt="Untitled" src="/img/untitled-40-7.png" />
            <div className="text-wrapper-2">Explore The Catalogue</div>
          </div>
          <div className="text-wrapper-3">BROWSE OUR FULL COLLECTION</div>
        </div>
        <div className="overlap-2">
          <div className="rectangle" />
          <div className="rectangle-2" />
          <div className="rectangle-3" />
          <div className="group">
            <div className="overlap-3">
              <div className="text-wrapper-4">patek phillipe</div>
              <div className="text-wrapper-5">NAUTILUS</div>
            </div>
            <div className="overlap-group-2">
              <div className="text-wrapper-6">rolex</div>
              <div className="text-wrapper-5">datejust</div>
            </div>
            <div className="overlap-4">
              <div className="text-wrapper-7">ap</div>
              <div className="text-wrapper-5">royal oak</div>
            </div>
          </div>
          <div className="discover">Shop Royal Oak</div>
          <img className="img" alt="Untitled" src="/img/untitled-40-7.png" />
          <div className="discover-2">Shop Nautilus</div>
          <img className="untitled-2" alt="Untitled" src="/img/untitled-40-7.png" />
          <div className="discover-3">Shop Datejust</div>
          <img className="untitled-3" alt="Untitled" src="/img/untitled-40-7.png" />
          <img className="image" alt="Image" src="/img/image-6.png" />
          <img className="image-2" alt="Image" src="/img/image-7.png" />
          <img className="apright" alt="Apright" src="/img/apright-1.gif" />
        </div>
        <div className="overlap-5">
          <img className="rectangle-4" alt="Rectangle" src="/img/rectangle-56.png" />
          <img className="lux-co-logo" alt="Lux co logo" src="/img/lux-co-logo-1.png" />
          <img className="rectangle-5" alt="Rectangle" src="/img/rectangle-59.png" />
          <div className="overlap-6">
            <div  className="text-wrapper-8">Learn More</div>
            <img className="untitled-4" alt="Untitled" src="/img/untitled-40-2.png" />
            <div className="overlap-wrapper">
              <div className="overlap-7">
                <div className="text-wrapper-9">Experience our streamlined service</div>
                <div className="text-wrapper-10">for selling luxury watches.</div>
              </div>
            </div>
          </div>
          <div className="text-wrapper-11">Sell / Consign</div>
          <div className="text-wrapper-12">Contact</div>
          <div className="text-wrapper-13">About Us</div>
          <div className="text-wrapper-14">Watches</div>
          <div className="overlap-8">
            <div className="text-wrapper-15">free</div>
            <div className="text-wrapper-16">WATCH VALUATION</div>
          </div>
          <p className="p">Discover the latest marvel in timekeeping technology: the ChronoMaster</p>
        </div>
        <SliderProjucts/>
        <div className="overlap-9" style={{zIndex:-1}}>
              <div className="rectangle-7" />
              <div className="text-wrapper-17">Reference Number: 26574TI.OO.1220TI.01</div>
              <p className="audemars-piguet">
                <span className="span">Audemars Piguet&nbsp;&nbsp; </span>
                <span className="text-wrapper-18">Perpetual Calendar</span>
              </p>
              <div className="text-wrapper-19">Reference Number: 326934-0003</div>
              <p className="rolex-sky-dweller">
                <span className="span">Rolex&nbsp;&nbsp; </span>
                <span className="text-wrapper-18">Sky Dweller</span>
              </p>
              <div className="text-wrapper-20">Reference Number: 5167R-001</div>
              <p className="patek-phillipe">
                <span className="span">Patek Phillipe&nbsp;&nbsp; </span>
                <span className="text-wrapper-18">Aquanaut</span>
              </p>
              <div className="text-wrapper-21">new</div>
              <div className="text-wrapper-22">ADDITIONS</div>
              <div className="discover-4">Price On Request</div>
              <div className="text-wrapper-23">Reference Number: RM72-01</div>
              <p className="richard-mille">
                <span className="span">Richard Mille&nbsp;&nbsp; </span>
                <span className="text-wrapper-18">RM72-01</span>
              </p>
              <img className="untitled-5" alt="Untitled" src="/img/untitled-40-27.png" />
              <div className="discover-5">Price On Request</div>
              <img className="untitled-6" alt="Untitled" src="/img/untitled-40-27.png" />
              <div className="discover-6">Price On Request</div>
              <img className="untitled-7" alt="Untitled" src="/img/untitled-40-27.png" />
              <div className="discover-7">Price On Request</div>
              <img className="untitled-8" alt="Untitled" src="/img/untitled-40-27.png" />
              <p className="text-wrapper-24">Discover the latest marvel in timekeeping technology: the ChronoMaster</p>
              <img className="group-2" alt="Group" src="/img/group-58-1.png" />
              <img className="group-3" alt="Group" src="/img/group-24-2.png" />
              <img className="group-4" alt="Group" src="/img/group-55-1.png" />
              <img className="group-5" alt="Group" src="/img/group-56-1.png" />
              <img className="group-6" alt="Group" src="/img/group-57-1.png" />
            </div>
        <div className="overlap-10" style={{zIndex:-1}}>
          <div className="rectangle-8" />
          <p className="text-wrapper-25">Discover the latest marvel in timekeeping technology: the ChronoMaster</p>
          <div className="text-wrapper-26">SHOP</div>
          <div className="text-wrapper-27">BY BRAND</div>
          <img className="group-7" alt="Group" src="/img/group-50.png" />
          <img className="group-8" alt="Group" src="/img/group-51.png" />
          <img className="group-9" alt="Group" src="/img/group-52.png" />
          <div className="untitled-wrapper">
            <img className="untitled-9" alt="Untitled" src="/img/untitled-48-1.png" />
          </div>
          <div className="text-wrapper-28">patek phillipe</div>
          <div className="discover-8">Discover The Collection</div>
          <img className="untitled-10" alt="Untitled" src="/img/untitled-40-23.png" />
          <div className="text-wrapper-29">rolex</div>
          <div className="discover-9">Discover The Collection</div>
          <img className="untitled-11" alt="Untitled" src="/img/untitled-40-23.png" />
          <div className="text-wrapper-30">richard mille</div>
          <div className="discover-10">Discover The Collection</div>
          <img className="untitled-12" alt="Untitled" src="/img/untitled-40-23.png" />
        </div>
        <SliderBrands/>
        <div className="overlap-11">
          <div className="overlap-12">
            <p className="text-wrapper-31">Discover the latest marvel in timekeeping technology: the ChronoMaster</p>
            <div className="text-wrapper-32">Learn More About Us</div>
            <img className="untitled-13" alt="Untitled" src="/img/untitled-40-7.png" />
          </div>
          <div className="overlap-13">
            <div className="text-wrapper-33">why shop</div>
            <div className="AT-LUX-CO">AT LUX &amp; CO?</div>
          </div>
          <img className="rectangle-9" alt="Rectangle" src="/img/rectangle-189.png" />
        </div>
        <div className="overlap-14">
          <div className="overlap-15">
            <div className="rectangle-10" />
            <div className="text-wrapper-34">WhatsApp</div>
            <img className="luxwhatsapp" alt="Luxwhatsapp" src="/img/luxwhatsapp-1.png" />
          </div>
          <img className="luxlinkedin" alt="Luxlinkedin" src="/img/luxlinkedin-1.png" />
          <p className="level-collins">
            Level 12, 440 Collins St,
            <br />
            MELBOURNE VIC 3000
            <br />
            AUSTRALIA
          </p>
          <div className="text-wrapper-35">address</div>
          <div className="text-wrapper-36">Rolex</div>
          <div className="text-wrapper-37">Audemars Piguet</div>
          <div className="text-wrapper-38">Patek Phillipe</div>
          <div className="text-wrapper-39">Richard Mille</div>
          <div className="text-wrapper-40">Omega</div>
          <div className="text-wrapper-41">Tudor</div>
          <div className="text-wrapper-42">collections</div>
          <div className="text-wrapper-43">Cookie Policy</div>
          <div className="text-wrapper-44">Return Policy</div>
          <div className="terms-conditions">Terms &amp; Conditions</div>
          <div className="legal-privacy">legal &amp; privacy</div>
          <p className="text-wrapper-45">Call now +61 449 992 363</p>
          <a className="text-wrapper-46" href="mailto:contact@luxandco.com" rel="noopener noreferrer" target="_blank">
            contact@luxandco.com
          </a>
          <div className="text-wrapper-47">contact us</div>
          <div className="text-wrapper-48">follow us</div>
          <img className="lux-footter-logo" alt="Lux footter logo" src="/img/lux-footter-logo-1.png" />
          <img className="luxyoutube" alt="Luxyoutube" src="/img/luxyoutube-1.png" />
          <img className="luxig" alt="Luxig" src="/img/luxig-1.png" />
          <div className="text-wrapper-49">reach us via whatsapp</div>
        </div>
        <div className="group-wrapper">
          <div className="overlap-group-wrapper">
            <div className="overlap-16">
              <div className="div-wrapper">
                <div className="text-wrapper-50">FEATURED MODELS</div>
              </div>
              <div className="text-wrapper-51">this month’s</div>
            </div>
          </div>
        </div>
      </div>
    </div>


    {/* MOBILE CODE BELOW THIS COMMENT */}


    <div className="HOME-MOBILE">
      <div className="div">
        <div className="overlap">
          <img className="rectangle" alt="Rectangle" src="/img/rectangle-52.png" />
          <img className="img" alt="Rectangle" src="/img/rectangle-56.png" />
          <img className="lux-co-logo" alt="Lux co logo" src="/img/lux-co-logo-1.png" />
          <img className="rectangle-2" alt="Rectangle" src="/img/rectangle-59.png" />
          <div className="text-wrapper">Learn More</div>
          <p className="discover-the-latest">
            Discover the latest marvel in timekeeping <br />
            technology: the ChronoMaster
          </p>
          <div className="text-wrapper-2">free</div>
          <div className="text-wrapper-3">WATCH VALUATION</div>
          <img className="untitled" alt="Untitled" src="/img/untitled-40-2.png" />
        </div>
        <div className="overlap-group">
          <img className="image" alt="Image" src="/img/image-6.gif" />
          <div className="overlap-2">
            <div className="discover">Browse All Variations</div>
            <img className="untitled-2" alt="Untitled" src="/img/untitled-40-19.png" />
            <div className="text-wrapper-4">patek phillipe</div>
            <div className="text-wrapper-5">NAUTILUS</div>
          </div>
        </div>
        <div className="overlap-3">
          <div className="overlap-4">
            <div className="discover-2">Browse All Variations</div>
            <img className="untitled-3" alt="Untitled" src="/img/untitled-40-19.png" />
            <div className="text-wrapper-6">DATEJUST</div>
          </div>
          <img className="rolexleft" alt="Rolexleft" src="/img/rolexleft-1.gif" />
        </div>
        <img className="rectangle-3" alt="Rectangle" src="/img/rectangle-200.png" />
        <div className="overlap-5">
          <div className="overlap-6">
            <div className="rectangle-4" />
            <img className="group" alt="Group" src="/img/group-65.png" />
            <img className="group-2" alt="Group" src="/img/group-66.png" />
            <div className="text-wrapper-7">patek phillipe</div>
            <div className="text-wrapper-8">richard mille</div>
            <p className="p">
              Discover the latest marvel in timekeeping <br />
              technology: the ChronoMaster
            </p>
            <div className="text-wrapper-9">shop</div>
            <div className="text-wrapper-10">BY BRAND</div>
            <div className="discover-3">Discover The Collection</div>
            <img className="untitled-4" alt="Untitled" src="/img/untitled-40-37.png" />
            <img className="untitled-5" alt="Untitled" src="/img/untitled-40-37.png" />
            <img className="group-3" alt="Group" src="/img/group-24-4.png" />
          </div>
          <div className="discover-4">Discover The Collection</div>
        </div>
        <div className="overlap-7">
          <div className="rectangle-5" />
          <div className="rectangle-6" />
          <img className="luxlinkedin" alt="Luxlinkedin" src="/img/luxlinkedin-1.png" />
          <p className="level-collins">
            Level 12, 440 Collins St,
            <br />
            MELBOURNE VIC 3000
            <br />
            AUSTRALIA
          </p>
          <div className="text-wrapper-11">address</div>
          <div className="text-wrapper-12">Rolex</div>
          <div className="text-wrapper-13">Audemars Piguet</div>
          <div className="text-wrapper-14">Patek Phillipe</div>
          <div className="text-wrapper-15">Richard Mille</div>
          <div className="text-wrapper-16">Omega</div>
          <div className="text-wrapper-17">Tudor</div>
          <div className="text-wrapper-18">collections</div>
          <div className="text-wrapper-19">Cookie Policy</div>
          <div className="text-wrapper-20">Return Policy</div>
          <div className="terms-conditions">Terms &amp; Conditions</div>
          <div className="legal-privacy">legal &amp; privacy</div>
          <p className="call-now">
            Call now:
            <br />
            +61 449 992 363
          </p>
          <div className="text-wrapper-21">WhatsApp</div>
          <a className="text-wrapper-22" href="mailto:contact@luxandco.com" rel="noopener noreferrer" target="_blank">
            contact@luxandco.com
          </a>
          <div className="text-wrapper-23">contact us</div>
          <div className="text-wrapper-24">follow us</div>
          <img className="lux-footter-logo" alt="Lux footter logo" src="/img/lux-footter-logo-1.png" />
          <img className="luxyoutube" alt="Luxyoutube" src="/img/luxyoutube-1.png" />
          <img className="luxig" alt="Luxig" src="/img/luxig-1.png" />
          <div className="text-wrapper-25">whatsapp us</div>
          <img className="luxwhatsapp" alt="Luxwhatsapp" src="/img/luxwhatsapp-1.png" />
        </div>
        <div className="overlap-8">
          <div className="overlap-9">
            <div className="text-wrapper-26">of luxury timepieces</div>
            <div className="text-wrapper-27">Explore The Catalogue</div>
            <img className="untitled-6" alt="Untitled" src="/img/untitled-40-7.png" />
          </div>
          <div className="text-wrapper-28">BROWSE OUR FULL COLLECTION</div>
        </div>
        <div className="overlap-10">
          <img className="group-4" alt="Group" src="/img/group-67.png" />
          <div className="overlap-group-wrapper">
            <div className="overlap-group-2">
              <div className="div-wrapper">
                <div className="text-wrapper-29">FEATURED MODELS</div>
              </div>
              <div className="text-wrapper-30">this month’s</div>
            </div>
          </div>
        </div>
        <div className="overlap-11">
          <div className="rectangle-4" />
          <div className="text-wrapper-31">ap</div>
          <div className="text-wrapper-32">ROYAL OAK</div>
          <div className="discover-5">Browse All Variations</div>
          <img className="untitled-7" alt="Untitled" src="/img/untitled-40-17.png" />
          <img className="apleft" alt="Apleft" src="/img/apleft-1.gif" />
        </div>
        <div className="overlap-12">
          <div className="rectangle-4" />
          <img className="img-2" alt="Rectangle" src="/img/rectangle-73.png" />
          <div className="text-wrapper-33">REF: 26574TI.OO.1220TI.01</div>
          <p className="audemars-piguet">
            <span className="span">Audemars Piguet&nbsp;&nbsp; </span>
            <span className="text-wrapper-34">Perpetual Calendar</span>
          </p>
          <div className="text-wrapper-35">REF: 326934-0003</div>
          <p className="rolex-sky-dweller">
            <span className="span">Rolex&nbsp;&nbsp; </span>
            <span className="text-wrapper-34">Sky Dweller</span>
          </p>
          <div className="discover-6">Price On Request</div>
          <div className="text-wrapper-36">Price On Request</div>
          <img className="group-5" alt="Group" src="/img/group-64.png" />
          <img className="img-2" alt="Untitled" src="/img/untitled-1-4.png" />
          <div className="text-wrapper-37">ADDITIONS</div>
          <p className="discover-the-latest-2">
            Discover the latest additions to our vast <br />
            collection of luxury Swiss watches.
          </p>
          <div className="text-wrapper-38">latest</div>
          <img className="untitled-8" alt="Untitled" src="/img/untitled-40-36.png" />
          <img className="untitled-9" alt="Untitled" src="/img/untitled-40-36.png" />
          <img className="group-6" alt="Group" src="/img/group-24-4.png" />
        </div>
      </div>
    </div>

    </div>
  );
};