import React, { Suspense, lazy,useEffect, useRef } from "react";
import { useState } from "react"
import { useLocation } from 'react-router-dom';
import Header from "./components/Header";
import Footer from "./components/Footer";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import "./styles/Calalouge.css";
import DOMPurify from 'dompurify';
const LazyProduct = lazy(() => import('./components/ProductCard'));
// import "./Catalouge.css";

// const Product = (product) => {
//   const navigate = useNavigate();
//   const itemInfo = product.product;

//   // Extract the first image path
//   const imagePathsArray = itemInfo.imagePaths ? itemInfo.imagePaths.split(',').map(path => path.trim()) : [];
//   const firstImagePath = imagePathsArray[0] || ''; // Default to empty string if no paths are present

//   return (
//     <div className="product" onClick={() => navigate(`/product/${itemInfo._id}`)}>
//       <div className="cat-product-overlay">
//         <h2 className="cat-product-header-sub">{itemInfo.refNumber}</h2>
//         <h1 className="cat-product-header">{itemInfo.title}</h1>
//         <div className="project-btn">
//           <img src="/img/untitled-40-24.png" alt=""/>
//           Price On Request
//         </div>
//         <h2 className="cat-product-header-sub">{itemInfo.name}</h2>
//       </div>
//       <img className="product-img" src={firstImagePath} alt={itemInfo.title || "Product Image"} />
//       <div className="product-background">
//         <img src="/img/products/CardBackground.png" alt=""/>
//       </div>
//     </div>
//   );
// };

const ProductWrapper = ({ product }) => {
  const [isInView, setIsInView] = useState(false);
  const ref = React.useRef();

  useEffect(() => {
      const observer = new IntersectionObserver(
          (entries) => {
              entries.forEach((entry) => {
                  if (entry.isIntersecting) {
                      setIsInView(true);
                      observer.disconnect();
                  }
              });
          },
          {
              threshold: 0.1, // Adjust as needed
          }
      );
      if (ref.current) {
          observer.observe(ref.current);
      }

      return () => {
          if (ref.current) {
              observer.unobserve(ref.current);
          }
      };
  }, []);

  return (
      <div ref={ref}>
          {isInView ? (
              <Suspense fallback={<div>Loading...</div>}>
                  <LazyProduct product={product} />
              </Suspense>
          ) : (
              <div>Loading...</div>
          )}
      </div>
  );
};

const FooterPages = ({products, itemsPerPage, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (products) {
      setTotalPages(Math.ceil(products.length / itemsPerPage));
    }
  }, [products, itemsPerPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    onPageChange(newPage);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;

    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 mx-1 rounded ${
            currentPage === i
              ? 'footer-page-btn-selected'
              : 'footer-page-btn'
          }`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="footer-pages-container">
      <div className="footer-pages-content">
        <div className="pages">
          <h1 className="footer-title">Pages</h1>
          {renderPageNumbers()}
          
        </div>
        <div className="cat-line-bottom">
          
        </div>
      </div>
    </div>
  );
}

const SwipeIndicator = () => {
  const [visible, setVisible] = useState(true);
  const timeoutRef = useRef(null);
  const lastScrollLeft = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

      // Check if there's a change in horizontal scroll position
      if (scrollLeft !== lastScrollLeft.current) {
        setVisible(false); // Hide indicator on horizontal scroll

        // Clear any existing timeout to avoid multiple triggers
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        // Show the swipe indicator after a delay once scrolling stops
        timeoutRef.current = setTimeout(() => {
          setVisible(true);
        }, 1000); // Delay in milliseconds

        // Update last horizontal scroll position
        lastScrollLeft.current = scrollLeft;
      }
    };

    // Attach scroll event listener to the document
    window.addEventListener('scroll', handleScroll);

    // Clean up event listener and timeout on component unmount
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`swipe-indicator ${visible ? '' : 'hidden'}`}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="arrow-right-swipe">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M14 12l-10 0" />
        <path d="M14 12l-4 4" />
        <path d="M14 12l-4 -4" />
        <path d="M20 4l0 16" />
      </svg>
    </div>
  );
};

export const Catalouge = (props) => {
  const location = useLocation();

  const [currentFilter, setCurrentFilter] = useState('')
  const [dropDown, setDropDown] = useState(false)
  const serverEndpoint = process.env.REACT_APP_SERVER_ENDPOINT;
  
  const [brands, setBrands] = useState()
  const [sizes, setSizes] = useState()
  const [complications, setComplications] = useState()
  const [models, setModels] = useState()
  const [functions, setFunctions] = useState()

  // Page states 
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 36;

  const [products, setProducts] = useState()

  const [searchParams, setSearchParams] = useState({
    searchTerm: '',
    brands: [],
    complication: [],
    size: [],
    model: [],
    functions: [],
  });

  const [triggerSearch, setTriggerSearch] = useState(false);
  // This value is for the external search
  const [addedParams, setAddedParams] = useState(false);

  // Slider values
  const sliderRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const onMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const onMouseLeave = () => {
    setIsDragging(false);
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  const onMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust the slide speed as needed
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };

  const sliderModelRef = useRef(null);

  const handleMouseDown = (e) => {
    e.preventDefault();
    const slider = sliderModelRef.current;
    let startX = e.pageX;
    let scrollLeft = slider.scrollLeft;

    const handleMouseMove = (e) => {
      const x = e.pageX - startX;
      slider.scrollLeft = scrollLeft - x;
    };

    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const getTotalFilters = () => {
    let totalFilters = 0;
  
    // Exclude searchTerm from the filter count
    totalFilters += searchParams.brands.length;
    totalFilters += searchParams.complication.length;
    totalFilters += searchParams.size.length;
    totalFilters += searchParams.model.length;
    totalFilters += searchParams.functions.length;
  
    return totalFilters;
  };  

  useEffect(() => {
    if (currentFilter !== '') {
      setDropDown(true)
      handleMenuContent()
    }
  },[currentFilter])

  useEffect(() => {
    // console.log('End point',serverEndpoint)
    // axios.get(`${serverEndpoint}`)
    // .then((r) => console.log(r))
    // .catch(e => console.log(e))
    window.scrollTo(0, 0); // Scroll to the top of the page

    const queryParams = new URLSearchParams(location.search);
    const params = {};
    queryParams.forEach((value, key) => {
      params[key] = value;
    });

    if ({...params}) {
      externalSearch({ ...props, ...params });
    } else {
      axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/get/brands`)
      .then(r => {
        setBrands(r.data.data)
        handleInitalProducts()
      })
    }

    axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/get/brands`)
        .then(r => {
          setBrands(r.data.data)
    })

  },[])

  const handleMenuContent = async () => {
    switch (currentFilter) {
      case 'Brand':
        await axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/get/brands`)
        .then(r => {
          setBrands(r.data.data)
        })
        break
      case 'Complication':
        await axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/functions/get`)
        .then(r => {
          setFunctions(r.data.functions)
        })
        await axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/get/complications`)
        .then(r => {
          setComplications(r.data.data)
        })
        break
      case "Size":
        await axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/get/size`)
        .then(r => {
          setComplications(r.data.data)
        })
        break
      case "Models":
        await axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/get/expandedbrandmodels`)
        .then(r => {
          setModels(r.data.data)
        })
        break
    }
  }

  // This is a dev finction
  const multiplyProducts = (products, multiplier) => {
    let multipliedProducts = [];
    for (let i = 0; i < multiplier; i++) {
        multipliedProducts = multipliedProducts.concat(products.map(product => ({
            ...product,
            id: `${product.id}-${i}` // Ensuring unique IDs for each product copy
        })));
    }
    return multipliedProducts;
};

  function sanitizeInput(input) {
    return DOMPurify.sanitize(input);
  }

  function isValidInput(input) {
    const regex = /^[a-zA-Z0-9\s]+$/;
    return regex.test(input);
  }

  const handleSearch = async () => {
    if (searchParams.searchTerm) {
      const sanitizedSearchTerm = sanitizeInput(searchParams.searchTerm);
      setSearchParams((prevParams) => ({
        ...prevParams,
        searchTerm: sanitizedSearchTerm,
      }));
    }

    if (searchParams) {
      await axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/search`, {
        params: {
          searchParams
        }
      }).then(r => {
        if (r.data) {
          // Sort the products by their brand
          const sortedProducts = r.data.sort((a, b) => {
            if (a.brand < b.brand) return -1;
            if (a.brand > b.brand) return 1;
            return 0;
          });
          setProducts(sortedProducts);
        }
      }).catch(error => {
        console.error('Error fetching products:', error);
      });
    }
  };

  // Function to toggle brand selection
  const toggleBrandSelection = (brandId) => {
    const isSelected = searchParams.brands.includes(brandId);
    setSearchParams({
      ...searchParams,
      brands: isSelected
        ? searchParams.brands.filter((id) => id !== brandId) // Remove if already selected
        : [...searchParams.brands, brandId], // Add if not selected
    });
  };

  const toggleComplicationSelection = (complicationId) => {
    const isSelected = searchParams.complication.includes(complicationId);
    setSearchParams({
      ...searchParams,
      complication: isSelected
        ? searchParams.complication.filter((id) => id !== complicationId) // Remove if already selected
        : [...searchParams.complication, complicationId], // Add if not selected
    });
  };  
  
  const toggleSizeSelection = (sizeId) => {
    const isSelected = searchParams.size.includes(sizeId);
    setSearchParams({
      ...searchParams,
      size: isSelected
        ? searchParams.size.filter((id) => id !== sizeId) // Remove if already selected
        : [...searchParams.size, sizeId], // Add if not selected
    });
  };  
  
  const toggleModelSelection = (modelId) => {
    const isSelected = searchParams.model.includes(modelId);
    setSearchParams({
      ...searchParams,
      model: isSelected
        ? searchParams.model.filter((id) => id !== modelId) // Remove if already selected
        : [...searchParams.model, modelId], // Add if not selected
    });
  };  
  
  const toggleFunctionsSelection = (functionId) => {
    const isSelected = searchParams.functions.includes(functionId);
    setSearchParams({
      ...searchParams,
      functions: isSelected
        ? searchParams.functions.filter((id) => id !== functionId) // Remove if already selected
        : [...searchParams.functions, functionId], // Add if not selected
    });
  };  

  const handleSearchTermChange = (event) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      searchTerm: event.target.value,
    }));
  };

  const handleInitalProducts = async () => {
    await axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/get/products`).then(res => {
      if (res.data.data) {
        setProducts(res.data.data)
      }
    })
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchTermSearch()
    }
  };

  const groupProductsByBrand = (products) => {
    if (!products) return {};
  
    const lowerSearchTerm = searchParams.searchTerm.toLowerCase();
  
    // Sort products based on how closely they or their brand match the searchTerm
    const sortedProducts = products.sort((a, b) => {
      const aBrandName = a.brand ? a.brand.name.toLowerCase() : ''; // Safely access brand.name
      const bBrandName = b.brand ? b.brand.name.toLowerCase() : ''; // Safely access brand.name
  
      const aMatch = 
        a.name.toLowerCase().includes(lowerSearchTerm) ||
        aBrandName.includes(lowerSearchTerm);
      const bMatch = 
        b.name.toLowerCase().includes(lowerSearchTerm) ||
        bBrandName.includes(lowerSearchTerm);
  
      return bMatch - aMatch; // Push closer matches to the front
    });
  
    // Group products by brand
    const groupedByBrand = sortedProducts.reduce((acc, product) => {
      const brandName = product.brand ? product.brand.name : 'Unknown'; // Handle null brands
      if (!acc[brandName]) {
        acc[brandName] = [];
      }
      acc[brandName].push(product);
      return acc;
    }, {});
  
    // Sort brands to bring the brand with the closest matching product or brand name to the top
    const sortedBrands = Object.keys(groupedByBrand).sort((a, b) => {
      const aBrandMatch = a.toLowerCase() === lowerSearchTerm;
      const bBrandMatch = b.toLowerCase() === lowerSearchTerm;
  
      if (aBrandMatch && !bBrandMatch) return -1; // a brand matches exactly
      if (!aBrandMatch && bBrandMatch) return 1;  // b brand matches exactly
  
      const aHasMatch = groupedByBrand[a].some(product =>
        product.name.toLowerCase().includes(lowerSearchTerm)
      ) || a.toLowerCase().includes(lowerSearchTerm);
  
      const bHasMatch = groupedByBrand[b].some(product =>
        product.name.toLowerCase().includes(lowerSearchTerm)
      ) || b.toLowerCase().includes(lowerSearchTerm);
  
      return bHasMatch - aHasMatch;
    });
  
    // Reorder the grouped products by sorted brands
    const sortedGroupedByBrand = sortedBrands.reduce((acc, brand) => {
      acc[brand] = groupedByBrand[brand];
      return acc;
    }, {});
  
    return sortedGroupedByBrand;
  };
  

  const groupedProducts = groupProductsByBrand(products);

  const externalSearch = (params) => {
    if (params) {
      if (params.brand) {
        setSearchParams(prevState => ({
          ...prevState,
          brands: [...prevState.brands, params.brand]
        }));
      }
      if (params.model) {
        setSearchParams(prevState => ({
          ...prevState,
          model: [...prevState.model, params.model]
        }));
      }
      if (params.searchTerm) {
        setSearchParams(prevState => ({
          ...prevState,
          searchTerm: params.searchTerm
        }));
      }
      setAddedParams(true)
    }
  }

  const searchTermSearch = async () => {
    setSearchParams((prevParams) => {
      if (prevParams.searchTerm.trim() !== '') {
        return {
          searchTerm: prevParams.searchTerm,
          brands: [],
          complication: [],
          size: [],
          model: [],
          functions: [],
        };
      }
      return prevParams;
    });
  
    setTriggerSearch(true); // Set trigger to true to initiate search
  };

  const handleResetFilters = () => {
    setSearchParams({
      searchTerm: '',
      brands: [],
      complication: [],
      size: [],
      model: [],
      functions: [],
    })
  }

  useEffect(() => {
    if (addedParams === true && searchParams) {
      handleSearch();
      setAddedParams(false); // Reset the flag after handling
    }
  }, [addedParams]);

  useEffect(() => {
    if (triggerSearch && searchParams.searchTerm.trim() !== '') {
      handleSearch();
      setTriggerSearch(false); // Reset the trigger
    } if (searchParams.searchTerm.trim() === '') {
      handleSearch();
      setTriggerSearch(false); // Reset the trigger
    }
  }, [searchParams, triggerSearch]);

  return (
    <div className="cat-page-container">
      
        <Header/>
      
      <div className="cat-search-container">
          <div className="cat-saerchbar-content">
            <input
              type="text"
              value={searchParams.searchTerm}
              onChange={handleSearchTermChange}
              placeholder="Search..."
              className="cat-saerchbar"
              onKeyDown={handleKeyDown}
            />
            <img className="search-icon" src="/img/search.png" alt="" onClick={() => searchTermSearch} />
          </div>
      </div>
      <div className="cat-filter-container">
        <div className="cat-filter-content">
          <div className="cat-filters">
            <p className="dd-options-title">Filter By</p>
            <p className={currentFilter === 'Brand' ? 'cat-selected-option' : 'cat-option'} onClick={() => {
              if (currentFilter === 'Brand') {
                setCurrentFilter('')
                setDropDown(false)
              } else {
                setCurrentFilter('Brand')
              }
            }}>Brand</p>
            <p className={currentFilter === 'Size' ? 'cat-selected-option' : 'cat-option'} onClick={() => {
              if (currentFilter === 'Size') {
                setCurrentFilter('')
                setDropDown(false)
              } else {
                setCurrentFilter('Size')
              }
            }}>Size</p>
            <p className={currentFilter === 'Complication' ? 'cat-selected-option' : 'cat-option'} onClick={() => {
              if (currentFilter === 'Complication') {
                setCurrentFilter('')
                setDropDown(false)
              } else {
                setCurrentFilter('Complication')
              }
            }}>Complication</p>
            <p className={currentFilter === 'Models' ? 'cat-selected-option' : 'cat-option'} onClick={() => {
              if (currentFilter === 'Models') {
                setCurrentFilter('')
                setDropDown(false)
              } else {
                setCurrentFilter('Models')
              }
            }}>Model</p>
          </div>
          <div className="cat-line">
            
          </div>
          {dropDown && currentFilter === 'Brand' && (
            <div className="cat-filter-dropdown">
            <div className="cat-dd-brands"
              ref={sliderRef}
              onMouseDown={onMouseDown}
              onMouseLeave={onMouseLeave}
              onMouseUp={onMouseUp}
              onMouseMove={onMouseMove}
            >
              {brands &&
                brands.map((brand) => {
                  const isSelected = searchParams.brands.includes(brand._id);
                  return (
                    <div
                      key={brand._id}
                      id="brand-container"
                      className={`cat-dd-brand ${isSelected ? 'selected' : ''}`}
                      onClick={() => toggleBrandSelection(brand._id)}
                      draggable="false"
                    >
                      <img
                        className="cat-dd-brand-img"
                        src={brand.imgLocation ? brand.imgLocation : ''}
                        alt={brand.name}
                        draggable="false"
                      />
                      <div className="cat-dd-brand-header">
                        <h1 className="cat-dd-brand-title">{brand.name}</h1>
                        <h2 className="cat-dd-brand-state">{brand.products.length}</h2>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          )}
          {dropDown && currentFilter === 'Complication' && (
              <div className="dd-options">
                <div className="dd-option-vert">
                <div className="dd-option-collection">
                <h1 className="dd-options-header">Mechanism</h1>
                {complications && complications.map((complication) => {
                  const isSelected = searchParams.complication.includes(complication._id);
                  if (complication.type === 'mechanism') {
                    return (
                      <div key={complication._id}>
                        <div className="checkbox-container" onClick={() => toggleComplicationSelection(complication._id)}>
                          <div className="checkbox-filter">
                            {
                              isSelected && (
                                <div className="checkbox-selected">
                                  
                                </div>
                              )
                            }
                          </div>
                          <h1 className="cat-dd-brand-title">{complication.name}</h1>
                          <h2 className="cat-dd-brand-state">{complication.products.length}</h2>
                        </div>
                      </div>
                    );
                  } 
                })}
                </div>
                <div className="dd-option-collection-grid">
                <h1 className="dd-options-header">Functions</h1>
                  <div className="dd-options-grid">
                  {functions && functions.map((productFunction) => {
                    const isSelected = searchParams.functions.includes(productFunction._id);
                      return (
                        <div key={productFunction._id}>
                          <div className="checkbox-container" onClick={() => toggleFunctionsSelection(productFunction._id)}>
                            <div className="checkbox-filter">
                              {
                                isSelected && (
                                  <div className="checkbox-selected">
                                  
                                  </div>
                                )
                              }
                            </div>
                            <h1 className="cat-dd-brand-title">{productFunction.name}</h1>
                            <h2 className="cat-dd-brand-state">{productFunction.products.length}</h2>
                          </div>
                        </div>
                      );
                  })}
                  </div>
                </div>
                </div>
              </div>
            )}
            {dropDown && currentFilter === 'Size' && (
              <div className="dd-options">
                <h1 className="dd-options-header">CASE DIAMETER</h1>
                {complications && complications.map((size) => {
                  const isSelected = searchParams.size.includes(size._id);
                  return (
                    <div key={size._id}>
                      <div className="checkbox-container" onClick={() => toggleSizeSelection(size._id)}>
                        <div className="checkbox-filter">
                          {
                            isSelected && (
                              <div className="checkbox-selected">
                              
                              </div>
                            )
                          }
                        </div>
                        <h1 className="cat-dd-brand-title">{size.name}</h1>
                        <h2 className="cat-dd-brand-state">{size.products.length}</h2>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {dropDown && currentFilter === 'Models' && (
              <div className="dd-options-models" ref={sliderModelRef} onMouseDown={handleMouseDown}>
                {models && models.map((brand) => {
                  return (
                    <div className="dd-option-collection" key={brand._id}>
                      <div className="dd-options-header-options">
                        <h1 className="dd-options-header">{brand.name}</h1>
                        <div className="dd-options-header-options-model">
                          {
                            brand && brand.models && brand.models.map((model) => {
                              const isSelected = searchParams.model.includes(model._id);
                              return (
                                <div className="checkbox-container" onClick={() => toggleModelSelection(model._id)}>
                                  <div className="checkbox-filter">
                                    {
                                      isSelected && (
                                        <div className="checkbox-selected">
                                          
                                        </div>
                                      )
                                    }
                                  </div>
                                  <h1 className="cat-dd-brand-title">{model.name}</h1>
                                  <h2 className="cat-dd-brand-state">{model.products.length}</h2>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div className="cat-dd-footer">
            {
              getTotalFilters() > 0?
              <div>
                <div className="cat-dd-brand-state-filters" style={{display: 'block'}}>
                  <p style={{direction:'underline', cursor:'pointer'}} onClick={() => handleResetFilters()}>Reset filters</p>
                  <p>Number selected filters: {getTotalFilters()}</p>
                </div>
              </div>
              :
              <div>
                
              </div>
            }
            <div style={{display:'flex', alignItems:'end', justifyContent:'end', flexDirection:'column'}}>
            {dropDown && currentFilter === 'Models' && (
              <SwipeIndicator />
            )}
              {
                dropDown && (
                  <button 
                    className="cat-filter-btn" 
                    onClick={() => {
                      setDropDown(false);
                      setCurrentFilter('');
                      setSearchParams(prevParams => ({
                          ...prevParams,
                          searchTerm: '' // Clear the searchTerm
                      }));
                      setTriggerSearch('true')
                    }}
                  >
                    Done
                  </button>
                )
              }
            </div>
            </div>
            {
              dropDown &&
              (
                <div className="cat-line-bottom-filter">
              
                </div>
              )
            }
        </div>
      </div>
      <div className="cat-iteams">
        <div className="">
          {products && products.length > 0 && 
            Object.keys(groupedProducts).map(brand => (
              <div key={brand._id}>
                <div className="seperation-container">
                  <h1 className="grid-items-seperation">{
                    brands && brands.map((brandData) => {
                      if (brandData.name === brand) {
                        return brandData.name
                      }
                    })
                  }</h1>
                </div>
                <div className="grid-items">
                    {groupedProducts[brand].map((product) => (
                        <ProductWrapper key={product.id} product={product} />
                    ))}
                </div>
              </div>
            ))
          }
        </div>
      </div>
      {/* <FooterPages
        products={products}
        itemsPerPage={itemsPerPage}
        onPageChange={handlePageChange}
      /> */}
      <Footer/>
    </div>
  );
};
