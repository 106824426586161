import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/AdminCreationMenu.css'; // Assume styles are defined here
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { useAuth } from './AuthContext';
import Fuse from 'fuse.js';

const StyledFunctionTag = ({ productFunction, handleRemoveFunction }) => {
  const tagStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '16px',
    padding: '4px 12px',
    margin: '4px',
    fontSize: '14px',
    color: '#333',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  };

  const nameStyle = {
    marginRight: '8px',
  };

  const deleteStyle = {
    color: '#ff4d4d',
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 'bold',
    transition: 'color 0.3s',
  };

  return (
    <div style={tagStyle}>
      <span style={nameStyle}>{productFunction.name}</span>
      <span 
        style={deleteStyle} 
        onClick={() => handleRemoveFunction(productFunction._id)}
        onMouseOver={(e) => e.target.style.color = '#ff1a1a'}
        onMouseOut={(e) => e.target.style.color = '#ff4d4d'}
      >
        ✕
      </span>
    </div>
  );
};

const CreationForm = ({
  setIsFullScreen,
  isFullScreen,
  formType,
  selectedItem,
  selectedColection,
  mode,
  getBrands,
  getComplication,
  getSizes,
  getProducts,
  getBrandModels,
  getFunctions,
  sizes,
  complications,
  brands,
  brandModels,
  functions,
  toast
}) => {
  const { getAdminId } = useAuth()

  const [formData, setFormData] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewUrl, setPreviewUrl] = useState('');

  useEffect(() => {
    // Initialize form data based on formType
    switch (formType) {
      case 'products':
        setFormData({
          title: '',
          name: '',
          description: '',
          brand: '',
          refNumber: '',
          model: '',
          complication: '',
          size: '',
          boxAndPapers: true,
          location: 'Hong Kong',
          availability: true,
          imagePaths: '',
          year: '',
          functions: [],
          imageFiles: [],
        });
        // Fetch brands, complications, and sizes
        fetchBrands();
        fetchComplications();
        fetchSizes();
        getSizes()
        getBrands()
        getComplication()
        getFunctions()
        getBrandModels()
        break;
      case 'brands':
        setFormData({ name: '', imageFiles: [], imagePaths: '', });
        break;
      case 'complication':
        setFormData({ name: '', imageUrl: '', type: '' });
        break;
      case 'size':
        setFormData({ name: '', imgLocation: '' });
        break;
      case 'models':
        setFormData({ name: '', brand: '', });
        getBrands()
        getBrandModels()
        break;
      case 'functions':
        setFormData({ name: '' });
        getFunctions()
        break
        default:
        setFormData({});
    }
    console.log('Complications',complications)
  }, [formType,selectedItem,isFullScreen]);

  useEffect(() => {
    console.log("MODE",mode)
    if (mode && mode === 'create') {
      console.log('CREATE MODE', mode)
      setFormData({
        title: '',
        name: '',
        description: '',
        brand: '',
        refNumber: '',
        model: '',
        complication: '',
        size: '',
        boxAndPapers: true,
        location: '',
        availability: true,
        imagePaths: '',
        year: '',
        functions: [],
        imageFiles: [],
      });
    }
  }, [mode])

  useEffect(() => {
    if (selectedItem) {
      setFormData(selectedItem);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (formType === 'product') {
      getSizes()
      getBrands()
      getComplication()
    } else if (formType === 'brands') {
      getSizes()
      getBrands()
      getComplication()
    } else if (formType === 'complications') {
      getComplication()
    }
  },[formType])

  const fetchBrands = () => {
    // Placeholder: Replace with actual API call
    
  };

  const fetchComplications = () => {
    // Placeholder: Replace with actual API call
    
  };

  const fetchSizes = async () => {
    // Placeholder: Replace with actual API call
    console.log('Sizes', sizes)
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  // Image preview

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const maxFileSize = 2 * 1024 * 1024; // 2MB file size limit
  
    if (file) {
      if (file.size > maxFileSize) {
        toast.error('File is too large')
        console.error(`${file.name} is too large, please select files smaller than 2MB.`);
        // Optionally, show a notification or alert to the user
        return;
      }
  
      if (!file.type.startsWith('image/')) {
        toast.error('File is not an image')
        console.error(`${file.name} is not an image, please select an image file.`);
        // Optionally, show a notification or alert to the user
        return;
      }
  
      setFormData((prevData) => {
        if (!prevData) {
          console.error('Previous form data is undefined');
          return { imageFiles: [file] };
        }
  
        console.log('Previous imageFiles:', prevData.imageFiles);
  
        const newImageFiles = Array.isArray(prevData.imageFiles)
          ? [...prevData.imageFiles, file]
          : [file];
  
        console.log('New imageFiles:', newImageFiles);
  
        return {
          ...prevData,
          imageFiles: newImageFiles,
        };
      });
    } else {
      console.warn('No file selected');
    }
  };
  

  const handleRemoveFile = (index) => {
    setFormData(prevData => ({
      ...prevData,
      imageFiles: prevData.imageFiles.filter((_, i) => i !== index)
    }));
  };

  const handleRemoveImageUrl = (index) => {
    const urlArray = formData.imagePaths.split(',').map(url => url.trim());
    urlArray.splice(index, 1);
    setFormData(prevState => ({
      ...prevState,
      imagePaths: urlArray.join(', ')
    }));
  };

  const renderFilePreviews = () => {
    if (!formData.imageFiles || !Array.isArray(formData.imageFiles)) {
      console.warn('imageFiles is not an array:', formData.imageFiles);
      return null; // Or return some placeholder/error message
    }

    return formData.imageFiles && formData.imageFiles.map((file, index) => (
      <div key={index} className="file-preview" style={filePreviewStyle}>
        {file.type.startsWith('image/') ? (
          <img 
            src={URL.createObjectURL(file)} 
            alt={`Preview ${index}`} 
            style={previewImageStyle} 
          />
        ) : (
          <div className="file-icon" style={fileIconStyle}>
            {file.name}
          </div>
        )}
        <button 
          onClick={() => handleRemoveFile(index)} 
          style={removeButtonStyle}
        >
          Remove
        </button>
      </div>
    ));
  };

  const imageUrlPreviews = () => {
    if (!formData.imagePaths) {
      return null; // Return null if there are no image paths
    }
  
    return formData.imagePaths.split(',').map((url, index) => (
      <div key={`url-${index}`} className="file-preview" style={filePreviewStyle}>
        <img 
          src={url.trim()} 
          alt={`URL Preview ${index}`} 
          style={previewImageStyle} 
        />
        <button 
          onClick={() => handleRemoveImageUrl(index)} 
          style={removeButtonStyle}
        >
          Remove
        </button>
      </div>
    ));
  };

  const renderImagePreviews = () => {
    if (!formData.imagePaths) {
      return null;
    }
  
    const imagePathsArray = formData.imagePaths.split(',').map(path => path.trim());
  
    console.log("Image Paths Array:", imagePathsArray); // Log paths for debugging
  
    return imagePathsArray.map((path, index) => (
      <div key={index} className="image-preview">
        <img src={path} alt={`Preview ${index + 1}`} style={{ width: '100px' }} />
      </div>
    ));
  };

  const renderImagePreview = () => {
    if (!formData.imgLocation) {
      return null;
    }
  
    return (
      <img src={formData.imgLocation} alt="Image Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
    );
  };  

  const handleBrandImageChange = (e) => {
    if (e.target.name === 'image') {
      const file = e.target.files[0];
      setFormData({ ...formData, imageFiles: [file] });
      
      // Create a preview URL for the image
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      if (file) {
        reader.readAsDataURL(file);
      } else {
        setPreviewUrl('');
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const createProduct = async () => {
    const adminId = await getAdminId()

    if (formData) {
      if (!formData.name || !formData.title || !formData) {
        toast.error('Name is missing')
        return
      }
    }

    if (adminId) {
      const productFormData = new FormData();

      // Append the adminId to the productFormData
      productFormData.append('adminId', adminId);

      console.log("formData before appending:", formData);

      Object.keys(formData).forEach(key => {
        if (formData[key] !== null && formData[key] !== undefined) {
          if (Array.isArray(formData[key])) {
            // If it's an array (like imageFiles or functions), append each item separately
            formData[key].forEach((item, index) => {
              if (key === 'imageFiles') {
                productFormData.append(`imageFiles`, item);
              } else {
                productFormData.append(`${key}[${index}]`, item);
              }
            });
          } else if (typeof formData[key] === 'object') {
            // If it's an object, stringify it
            productFormData.append(key, JSON.stringify(formData[key]));
          } else {
            productFormData.append(key, formData[key]);
          }
        }
      });
      
      // console.log('productFormData', productFormData)
      // console.log("PFD entries:");
      // for (let [key, value] of productFormData.entries()) {
      //   console.log(key, value);
      // }

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/create/product`,
            productFormData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        console.log("Response from server:", res.data);

        setIsFullScreen(!isFullScreen);
        await getProducts();
      } catch (error) {
        console.error('Error creating product:', error);
      }
      return;
    }
  }

  const updateProduct = async (e) => {
    const adminId = await getAdminId()
    if (adminId) {
      const productFormData = new FormData();

      // Append the adminId to the productFormData
      productFormData.append('adminId', adminId);
      productFormData.append('productId', selectedItem._id)

      console.log("formData before appending:", formData);

      Object.keys(formData).forEach(key => {
        if (formData[key] !== null && formData[key] !== undefined) {
          if (Array.isArray(formData[key])) {
            // If it's an array (like imageFiles or functions), append each item separately
            formData[key].forEach((item, index) => {
              if (key === 'imageFiles') {
                productFormData.append(`imageFiles`, item);
              } else {
                productFormData.append(`${key}[${index}]`, item);
              }
            });
          } else if (typeof formData[key] === 'object') {
            // If it's an object, stringify it
            productFormData.append(key, JSON.stringify(formData[key]));
          } else {
            productFormData.append(key, formData[key]);
          }
        }
      });
      
      console.log('productFormData', productFormData)
      console.log("PFD entries:");
      for (let [key, value] of productFormData.entries()) {
        console.log(key, value);
      }

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/update/product`,
          productFormData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        console.log("Response from server:", res.data);

        setIsFullScreen(!isFullScreen);
        await getProducts();
      } catch (error) {
        console.error('Error creating product:', error);
      }
      return;
    }
  }

  const updateBrand = async () => {
    const adminId = await getAdminId()
    
    if (adminId) {
      const brandFormData = new FormData();

      brandFormData.append('adminId', adminId);
      brandFormData.append('brandId', selectedItem._id);

      Object.keys(formData).forEach(key => {
        if (formData[key] !== null && formData[key] !== undefined) {
          if (Array.isArray(formData[key])) {
            // If it's an array (like imageFiles or functions), append each item separately
            formData[key].forEach((item, index) => {
              if (key === 'imageFiles') {
                brandFormData.append(`imageFiles`, item);
              } else {
                brandFormData.append(`${key}[${index}]`, item);
              }
            });
          } else if (typeof formData[key] === 'object') {
            // If it's an object, stringify it
            brandFormData.append(key, JSON.stringify(formData[key]));
          } else {
            brandFormData.append(key, formData[key]);
          }
        }
      });

      const res = await axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/admin/update/brand`,
        brandFormData
      ,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((r) => console.log(r))

      setIsFullScreen(!isFullScreen);
      await getBrands()
      return
    }
  }

  const createBrand = async () => {
    const adminId = await getAdminId()
    
    if (adminId) {
      const brandFormData = new FormData();

      brandFormData.append('adminId', adminId);

      console.log(formData.imageFiles)

      Object.keys(formData).forEach(key => {
        if (formData[key] !== null && formData[key] !== undefined) {
          if (Array.isArray(formData[key])) {
            // If it's an array (like imageFiles or functions), append each item separately
            formData[key].forEach((item, index) => {
              if (key === 'imageFiles') {
                brandFormData.append(`imageFiles`, item);
              } else {
                brandFormData.append(`${key}[${index}]`, item);
              }
            });
          } else if (typeof formData[key] === 'object') {
            // If it's an object, stringify it
            brandFormData.append(key, JSON.stringify(formData[key]));
          } else {
            brandFormData.append(key, formData[key]);
          }
        }
      });

      console.log("PFD entries:");
      for (let [key, value] of brandFormData.entries()) {
        console.log(key, value);
      }

      const res = await axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/admin/create/brand`, brandFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((r) => {
        console.log(r)
        toast.success('Brand Created')
      }).catch(e => {
        toast.error('Something went wrong')
      })


      setIsFullScreen(!isFullScreen);
      await getBrands()
      return
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Submit the form data to your API
    console.log(`Submitting ${formType}:`, formData);
    if(selectedItem) {
      const adminId = await getAdminId()
      switch (formType) {
        case 'brands':
          await updateBrand()
          break;
        case 'complications':
          if (adminId) {
            axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/admin/update/complication`,{
              formData,
              complicationId: selectedItem._id,
              adminId,
            }).then((r) => console.log(r))

            setIsFullScreen(!isFullScreen);
            await getComplication()
            return
          }
          break;
        case 'size':
          if (adminId) {
            axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/admin/update/size`,{
              formData,
              sizeId: selectedItem._id,
              adminId,
            }).then((r) => console.log(r))

            setIsFullScreen(!isFullScreen);
            await getSizes()
            return
          }
          break;
        case 'products':
          updateProduct()
          break;
        case 'models':
            if (adminId) {
              axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/admin/update/brandmodel`,{
                formData,
                brandmodelId: selectedItem._id,
                adminId,
              })
              .then((r) => console.log(r))
              .catch(e => {
                console.log(e)
              })
              
              setIsFullScreen(!isFullScreen);
              await getProducts()
              return
            }
            break;
        case 'functions':
            if (adminId) {
              axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/function/admin/update`,{
                formData,
                updateFunctionId: selectedItem._id,
                adminId,
              })
              .then((r) => console.log(r))
              .catch(e => {
                console.log(e)
              })
              
              setIsFullScreen(!isFullScreen);
              await getFunctions()
              return
            }
            break;
        default:
          console.error('Unknown form type');
          throw new Error('Unknown form type');
      }
      toast.success('Updated')
    } else {
      const adminId = await getAdminId()
      switch (formType) {
        case 'brands':
          await createBrand()
          break;
        case 'complications':
          if (adminId) {
            axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/admin/create/complication`,{
              formData,
              adminId,
            }).then((r) => console.log(r))
            
            setIsFullScreen(!isFullScreen);
            await getComplication()
            return
          }
          break;
        case 'size':
          if (adminId) {
            axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/admin/create/size`,{
              formData,
              adminId,
            }).then((r) => console.log(r))
            
            setIsFullScreen(!isFullScreen);
            await getSizes()
            return
          }
          break;
        case 'products':
          createProduct()
          break;
        case 'models':
          if (adminId) {
              axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/admin/create/brandmodel`,{
                formData,
                adminId,
              })
              .then((r) => console.log(r))
              .catch(e => {
                console.log(e)
              })
              
              setIsFullScreen(!isFullScreen);
              await getBrandModels()
              return
          }
            break;
        case 'functions':
          console.log('getting functions')
          if (adminId) {
              axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/function/admin/create`,{
                formData,
                adminId,
              })
              .then((r) => console.log(r))
              .catch(e => {
                console.log(e)
              })
              
              setIsFullScreen(!isFullScreen);
              await getFunctions()
              return
          }
            break;
        default:
          console.error('Unknown form type');
          throw new Error('Unknown form type');
      }
      toast.success('Created')
    }
  };

  const handleAddFunction = (e) => {
    if (e.target.name === 'image') {
      const file = e.target.files[0];
      setFormData(prevData => ({
        ...prevData,
        imageFiles: file ? [file] : [] // Only keep the new file, replacing any existing one
      }));
      
      // Create a preview URL for the image
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setPreviewUrl('');
      }
    } else {
      setFormData(prevData => ({
        ...prevData,
        [e.target.name]: e.target.value
      }));
    }
  };

  const handleRemoveFunction = (id) => {
    setFormData((prevState) => ({
      ...prevState,
      functions: prevState.functions.filter(func => func._id !== id),
    }));
  };

  useEffect(() => {
    console.log('FormData:',formData)
  },[formData])

  const inputStyle = {
    backgroundColor: '#ffffff',
    color: '#333333',
    padding: '10px 12px',
    fontSize: '16px',
    border: '1px solid #606060',
    borderRadius: '4px',
    outline: 'none',
    transition: 'border-color 0.3s, box-shadow 0.3s',
    width: '100%',
    maxWidth: '500px',
    boxSizing: 'border-box'
  };

  const textareaStyle = {
    backgroundColor: '#ffffff',
    color: '#333333',
    padding: '10px 12px',
    fontSize: '16px',
    border: '1px solid #606060',
    borderRadius: '4px',
    outline: 'none',
    transition: 'border-color 0.3s, box-shadow 0.3s',
    width: '100%',
    maxWidth: '500px',
    minHeight: '100px',
    boxSizing: 'border-box',
    fontFamily: 'inherit',
    lineHeight: '1.5'
  };

  const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    padding: '8px 0',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    backgroundColor: '#ffffff',
    maxWidth: '500px',  // Limit the width to 500px
    width: '100%',      // Take full width up to 500px
    marginBottom: '1rem'
  };

  const inputContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  };

  const labelStyle = {
    marginBottom: '4px',
    fontWeight: 'bold',
  };


  const previewContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
    padding: '8px 0',
  };

  const filePreviewStyle = {
    width: '120px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
  };

  const previewImageStyle = {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    border: '1px solid #ccc',
    borderRadius: '4px',
  };

  const fileIconStyle = {
    width: '100px',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '12px',
    textAlign: 'center',
    wordBreak: 'break-word',
    padding: '4px',
  };

  const removeButtonStyle = {
    padding: '4px 8px',
    backgroundColor: '#ff4d4f',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '12px',
  };

  const renderFields = () => {
    switch (formType) {
      case 'products':
        return (
          <>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input id="title" name="title" value={formData.title} onChange={handleChange} style={inputStyle} required />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea id="description" name="description" value={formData.description} onChange={handleChange} style={textareaStyle} required />
            </div>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input id="name" name="name" value={formData.name} onChange={handleChange} style={inputStyle} required />
            </div>
            <div className="form-group">
              <label htmlFor="brand">Brand</label>
              <select name="brand" value={formData.brand} onChange={handleChange} style={inputStyle}>
                <option value="">Select a brand</option>
                {brands && brands.length > 0 && brands.map(brand => (
                  <option key={brand._id} value={brand._id}>{brand.name}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="refNumber">Reference Number</label>
              <input id="refNumber" name="refNumber" value={formData.refNumber} onChange={handleChange} style={inputStyle} required />
            </div>
            <div className="form-group">
              <label htmlFor="refNumber">Model</label>
              <select name="model" value={formData.model} onChange={handleChange} style={inputStyle}>
                <option value="">Select a Model</option>
                {brandModels && brandModels.length > 0 && brandModels.map(model => (
                  <option key={model._id} value={model._id}>{model.name}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="complication">Complication</label>
              <select name="complication" value={formData.complication} onChange={handleChange} style={inputStyle}>
                <option value="">Select a complication</option>
                {complications && complications.length > 0 && complications.map(complication => (
                  <option key={complication._id} value={complication._id}>{complication.name}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="productFunctions">Functions</label>
              <select name="productFunctions" value={formData.productFunctions} onChange={handleAddFunction} style={inputStyle}>
                <option value="">Select a function</option>
                {functions && functions.length > 0 && functions.map(productFunction => (
                  <option key={productFunction._id} value={productFunction._id}>{productFunction.name}</option>
                ))}
              </select>
            </div>
            <div style={containerStyle}>
            {
              formData.functions && formData.functions.map(productFunction => (
                <StyledFunctionTag 
                  key={productFunction._id}
                  productFunction={productFunction}
                  handleRemoveFunction={handleRemoveFunction}
                />
              ))
            }
            </div>
            <div style={containerStyle}>
              <div style={inputContainerStyle}>
                <label htmlFor="fileInput" style={labelStyle}>Upload Files</label>
                <input 
                  type="file" 
                  id="fileInput" 
                  onChange={handleFileChange} 
                  style={inputStyle} 
                  multiple 
                  accept="image/*"
                />
              </div>
              <div style={previewContainerStyle}>
                {imageUrlPreviews()}
                {renderFilePreviews()}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="size">Size</label>
              <select name="size" value={formData.size} onChange={handleChange}>
                <option value="">Select a size</option>
                {sizes && sizes.length > 0 && sizes.map(size => (
                  <option key={size._id} value={size._id}>{size.name}</option>
                ))}
              </select>
            </div>
            <div className="form-group checkbox" style={{ width:'200px'}}>
              <input
                type="checkbox"
                id="boxAndPapers"
                name="boxAndPapers"
                checked={formData.boxAndPapers}
                onChange={handleChange}
              />
              <p htmlFor="boxAndPapers">Box and Papers</p>
            </div>
            <div className="form-group">
              <label htmlFor="location">Location</label>
              <input id="location" name="location" value={formData.location} onChange={handleChange} style={inputStyle} required />
            </div>
            <div className="form-group">
              <label htmlFor="year">Year</label>
              <input id="year" name="year" value={formData.year} onChange={handleChange} style={inputStyle} required />
            </div>
            <div className="form-group checkbox">
              <input
                type="checkbox"
                id="availability"
                name="availability"
                checked={formData.availability}
                onChange={handleChange}
              />
              <p htmlFor="availability">Available</p>
            </div>
          </>
        );      
      case 'brands':
        return (
          <>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input id="name" name="name" value={formData.name} onChange={handleChange} style={inputStyle} required />
              <label htmlFor="image">Upload Image</label>
              <input
                type="file"
                id="image"
                name="image"
                onChange={handleBrandImageChange}
                accept="image/*"
                style={inputStyle}
              />
              
                  {previewUrl && (
                    <div>
                      <h4>Image Preview:</h4>
                      <img src={previewUrl} alt="Preview" style={{ maxWidth: '200px', marginTop: '10px' }} />
                    </div>
                  )}
                  {formData.imgLocation && !previewUrl && (
                    <div>
                      <h4>Image Preview:</h4>
                      <img src={formData.imgLocation? formData.imgLocation : ''} alt="Preview" style={{ maxWidth: '200px', marginTop: '10px' }} />
                    </div>
                  )}
            </div>
          </>
        );
      case 'size':
        return (
          <>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input id="name" name="name" value={formData.name} onChange={handleChange} style={inputStyle} required />
            </div>
          </>
        );
      case 'complications':
        return (
          <>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input id="name" name="name" value={formData.name} onChange={handleChange} style={inputStyle} required />
              <label htmlFor="brand">Related Type</label>
              <select name="type" value={formData.brand} onChange={handleChange} style={inputStyle}>
                <option value="">Select a type</option>
                <option key="mechanism" value="mechanism">MECHANISM</option>
                <option key="function" value="function">FUNCTION</option>
              </select>
            </div>
          </>
        );
      case 'models':
        return (
          <>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input id="name" name="name" value={formData.name} onChange={handleChange} style={inputStyle} required />
              <div className="form-group">
              <label htmlFor="brand">Related Brand</label>
              <select name="brand" value={formData.brand} onChange={handleChange} style={inputStyle}>
                <option value="">Select a Brand</option>
                {brands && brands.length > 0 && brands.map(brand => (
                  <option key={brand._id} value={brand._id}>{brand.name}</option>
                ))}
              </select>
            </div>
            </div>
          </>
        );
      case 'functions':
        return (
          <>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input id="name" name="name" value={formData.name} onChange={handleChange} style={inputStyle} required />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="creation-form">
      <h2>Create New {formType.charAt(0).toUpperCase() + formType.slice(1)}</h2>
      <form onSubmit={handleSubmit}>
        {renderFields()}
        <button type="submit">{mode === 'create' ? 'Create' : 'Update'} {formType.charAt(0).toUpperCase() + formType.slice(1)}</button>
      </form>
    </div>
  );
};

export default CreationForm;