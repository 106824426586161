import React from 'react';
import { motion, useInView } from 'framer-motion';

const TextAnimation = ({ children, animationVariants, delay = 0 }) => {
  const ref = React.useRef();
  const isInView = useInView(ref, { once: true });

  const defaultVariants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        ease: 'easeInOut',
        delay
      }
    }
  };

  const mergedVariants = animationVariants ? {
    ...animationVariants,
    visible: {
      ...animationVariants.visible,
      transition: {
        ...animationVariants.visible.transition,
        delay
      }
    }
  } : defaultVariants;

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={mergedVariants}
    >
      {children}
    </motion.div>
  );
};

export default TextAnimation;
