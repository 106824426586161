import React, { useState } from 'react';
import axios from 'axios';

const styles = {
  container: {
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    textAlign: 'center',
    color: '#333',
  },
  fileInput: {
    display: 'none',
  },
  fileLabel: {
    display: 'inline-block',
    padding: '10px 15px',
    backgroundColor: '#007bff',
    color: 'white',
    borderRadius: '4px',
    cursor: 'pointer',
    marginBottom: '10px',
  },
  button: {
    display: 'block',
    width: '100%',
    padding: '10px',
    backgroundColor: '#28a745',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  preview: {
    maxWidth: '100%',
    marginTop: '20px',
  },
};

const UploadImage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setPreviewUrl(URL.createObjectURL(file));
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file first!');
      return;
    }
  
    const formData = new FormData();
    formData.append('image', selectedFile);
    
    // Add additional data
    formData.append('productName', 'ProductName'); // Assuming you have a productName state or variable
    formData.append('description', 'This is sone text'); // Add more fields as needed
    formData.append('price', 699);
    // Add any other fields you need
  
    try {
      console.log('req data',formData)
      const response = await axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/uploadimage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Upload successful:', response.data);
      alert('Image and product data uploaded successfully!');
    } catch (error) {
      console.error('Error uploading image and data:', error);
      alert('Failed to upload image and data. Please try again.');
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Upload Image</h1>
      <input
        type="file"
        id="fileInput"
        style={styles.fileInput}
        onChange={handleFileSelect}
        accept="image/*"
      />
      <label htmlFor="fileInput" style={styles.fileLabel}>
        Select Image
      </label>
      <button onClick={handleUpload} style={styles.button}>
        Upload
      </button>
      {previewUrl && (
        <img src={previewUrl} alt="Preview" style={styles.preview} />
      )}
    </div>
  );
};

export default UploadImage;