import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { redirect, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Cookies from 'js-cookie';

const LockIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
  </svg>
);

const AdminLogin = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  
  
  useEffect(() => {
    const localStoreCookie = Cookies.get('localstore');
    if (localStoreCookie) {
      navigate('/dashboardinventory')
    }
  },[navigate])

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (username && password) {
        await login(username,password)
        const localStoreCookie = Cookies.get('localstore');
        if (localStoreCookie) {
          navigate('/dashboardinventory')
        }
    }
  };

  return (
    <div className="admin-login-container">
      <div className="login-box">
        <div className="icon-container">
          <LockIcon />
        </div>
        <h2 className="login-title">Admin Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <button type="submit" className="login-button">
            Log In
          </button>
        </form>
      </div>
      <style jsx>{`
        .admin-login-container {
          min-height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f3f4f6;
        }
        .login-box {
          background-color: white;
          padding: 2rem;
          border-radius: 0.5rem;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          width: 100%;
          max-width: 400px;
        }
        .icon-container {
          display: flex;
          justify-content: center;
          margin-bottom: 1.5rem;
          color: #3b82f6;
        }
        .login-title {
          font-size: 1.5rem;
          font-weight: bold;
          text-align: center;
          margin-bottom: 1.5rem;
        }
        .input-group {
          margin-bottom: 1rem;
        }
        .input-group label {
          display: block;
          font-size: 0.875rem;
          font-weight: 500;
          margin-bottom: 0.5rem;
        }
        .input-group input {
          width: 100%;
          padding: 0.5rem;
          border: 1px solid #d1d5db;
          border-radius: 0.25rem;
          font-size: 1rem;
        }
        .error-message {
          color: #ef4444;
          font-size: 0.875rem;
          margin-bottom: 1rem;
        }
        .login-button {
          width: 100%;
          background-color: #3b82f6;
          color: white;
          padding: 0.5rem 1rem;
          border: none;
          border-radius: 0.25rem;
          font-size: 1rem;
          cursor: pointer;
          transition: background-color 0.2s;
        }
        .login-button:hover {
          background-color: #2563eb;
        }
      `}</style>
    </div>
  );
};

export default AdminLogin;