import React, { useState, useEffect, useRef } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import '../styles/SliderProducts.css'
import Slider from "react-slick";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/navigation';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Product = (props) => {
    const navigate = useNavigate();
    const handleGoToProduct = () => {
        navigate(`/${props.productInfo._id}`, { replace: true });
        window.location.reload(); // This forces the reload
    };

    const getFirstImage = (imagePaths) => {
        if (!imagePaths) return ''; // Handle case where imagePaths might be null or undefined
        const images = imagePaths.split(',');
        return images[0] || ''; // Return the first image or an empty string if there are no images
    };    

    const firstImage = getFirstImage(props.productInfo.imagePaths);

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';
    };
    
    const brandName = props.productInfo.brand ? truncateText(props.productInfo.brand.name, 20) : '';
    const productName = truncateText(props.productInfo.name, 20);

    return (
        <div className='home-slider-product' onClick={handleGoToProduct}>
            <div className='product-background-image'>
                <img src='/img/cardbackground3.png' alt="Watch" />
            </div>
            <div className='product-image-pageslider'>
                <img src={firstImage} alt="Watch" />
            </div>
            <div className='product-discription'>
                <div className='product-header'>
                    <p className='product-header-name'>{brandName}</p>
                    <p className='product-header-thin-margin'>{productName}</p>
                </div>
                <p className='product-header-thin'>Reference Number: {props.productInfo.refNumber}</p>
                <div className='product-footer'>
                    <img className='product-logo' src="/img/untitled-40-27.png" alt="Lux And Co" />
                    <p>Price On Request</p>
                </div>
            </div>
        </div>
    )
}

// const products = [
//     {
//         'name': 'RM69 Erotic',
//         'brand': 'Richard Mille',
//         'refNumber': 'RM69',
//         'img': '/img/products/SliderImage6.png',
//         '_id': process.env.REACT_APP_PPSLID1
//     },
//     {
//         'name': 'Royal Oak Skeleton',
//         'brand': 'Audemars Piguet',
//         'refNumber': '16204OR.OO.1240OR.01',
//         'img': '/img/products/SliderImage2.png',
//         '_id': process.env.REACT_APP_PPSLID2
//     },
//     {
//         'name': 'Aquanaut Luce Haute Joaillerie',
//         'brand': 'Patek Phillipe',
//         'refNumber': '5062/450R-001',
//         'img': '/img/products/SliderImage1.png',
//         '_id': process.env.REACT_APP_PPSLID3
//     },
//     {
//         'name': 'RM57-01 Jackie Chan',
//         'brand': 'Richard Mille',
//         'refNumber': 'RM57-01',
//         'img': '/img/products/SliderImage3.png',
//         '_id': process.env.REACT_APP_PPSLID4
//     },
//     {
//         'name': 'RM67-01 TiRM67-01 Titanium',
//         'brand': 'Richard Mille',
//         'refNumber': 'RM67-01 Ti',
//         'img': '/img/products/SliderImage5.png',
//         '_id': process.env.REACT_APP_PPSLID5
//     },
//     {
//         'name': 'Grand Complications',
//         'brand': 'Patek Phillipe',
//         'refNumber': '5374/300P-001',
//         'img': '/img/products/SliderImage.png',
//         '_id': process.env.REACT_APP_PPSLID6
//     },
// ];

const SliderProjucts = () => {
    const [products, setProducts] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderRef = useRef(null);
    const sliderContentRef = useRef(null);
    const [maxScroll, setMaxScroll] = useState(0);
    const [isEnd, setIsEnd] = useState(false);
    const offset = 2; // Adjust based on your design (0-based index)

    const slidesToShow = 1; // Adjust based on your design
    const slidesToScroll = 5; // Adjust based on your design
    const totalSlides = products.length;

    const maxIndex = totalSlides - slidesToShow;

    const [clickCount, setClickCount] = useState(0); // Track number of clicks

    const getProducts = async () => {
        axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/get/sliderproducts`).then(r => {
            console.log("Responce",r)
            if (r.data.data) {
                setProducts(r.data.data)
            }
            console.log("Products",products)
        })
    }

    const handleAfterChange = (index) => {
        setCurrentIndex(index);
        setClickCount((prevCount) => prevCount + 1); // Increment click count

        // Check if the current slide is the last one
        if (index === products.length - 1) {
            setIsEnd(true); // Update state when at the end
        }
    };

    function SampleNextArrow(props) {
        const { currentIndex, maxIndex, onClick } = props;
        const isVisible = currentIndex < maxIndex;
        return isVisible ? (
            <div className="slider-arrow-right" onClick={onClick}>
                <img src="/img/group-24-4.png" alt="Next" />
            </div>
        ) : null;
    }


    function SamplePrevArrow(props) {
        const { currentIndex, onClick } = props;
        const isVisible = currentIndex > 0;
        return isVisible ? (
            <div className="slider-arrow-left" onClick={onClick}>
                <img src="/img/group-24-4.png" alt="Previous" />
            </div>
        ) : null;
    }
    

    const sliderOffsetStyle = {
        paddingRight: isEnd ? '20px' : '0',
    };
    
    const settings = {
        className: "slider variable-width",
        // dots: true,
        infinite: false,
        // centerMode: true,
        slidesToShow,
        slidesToScroll: 1,
        variableWidth: true,
        ref: sliderRef, // Attach the ref here
        beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
        afterChange: handleAfterChange,
        nextArrow: <SampleNextArrow currentIndex={currentIndex} maxIndex={maxIndex} />,
        prevArrow: <SamplePrevArrow currentIndex={currentIndex} />
      };

      useEffect(() => {
        getProducts()
      },[])

    return (
        <div className='productpage-container'>
            <div className='slider-container' ref={sliderRef}>
                {/* <Slider
                    {...settings}
                >
                    {products.map((product, index) => (
                        <Product key={index} productInfo={product} />
                    ))}
                </Slider> */}
                <SliderTest products={products}/>
            </div>
        </div>
    );
}

export default SliderProjucts;

const SliderTest = (props) => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const swiperRef = useRef(null);
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    const handleSwiperInit = (swiper) => {
        swiperRef.current = swiper; // Store Swiper instance in ref
        swiper.params.navigation.prevEl = prevRef.current;
        swiper.params.navigation.nextEl = nextRef.current;
        swiper.navigation.init();
        swiper.navigation.update();

        // Set initial states
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);

        // Event listeners
        swiper.on('slideChange', () => {
            setIsBeginning(swiper.isBeginning);
            setIsEnd(swiper.isEnd);
        });

        swiper.on('reachEnd', () => {
            setIsEnd(true);
        });

        swiper.on('fromEdge', () => {
            setIsBeginning(swiper.isBeginning);
            setIsEnd(swiper.isEnd);
        });
    };

    useEffect(() => {
        // Update Swiper instance if it exists when products change
        if (swiperRef.current) {
            swiperRef.current.update(); // Trigger update to recalculate slides
            setIsBeginning(swiperRef.current.isBeginning);
            setIsEnd(swiperRef.current.isEnd);
        }
    }, [props.products]); // Re-run when products change

    return (
        <div>
            <div className="swiper-nav-buttons">
                <img 
                    ref={prevRef} 
                    src="/img/group-24-4.png" 
                    alt="Previous" 
                    className="slider-arrow-left" 
                    style={{
                        height: '60px', 
                        width: '60px', 
                        transform: 'rotate(180deg)', 
                        display: isBeginning ? 'none' : 'block'
                    }}
                />
                <img 
                    ref={nextRef} 
                    src="/img/group-24-4.png" 
                    alt="Next" 
                    className="slider-arrow-right" 
                    style={{
                        height: '60px', 
                        width: '60px', 
                        display: isEnd ? 'none' : 'block'
                    }}
                />
            </div>
            <Swiper
                watchSlidesProgress={true}
                spaceBetween={10}
                className="mySwiper"
                modules={[Navigation]}
                onSwiper={handleSwiperInit} // Initialize Swiper and set up event listeners
                breakpoints={{
                    375: {
                        slidesPerView: 1.07,
                    },
                    790: {
                        slidesPerView: 1.1,
                    },
                    1000: {
                        slidesPerView: 1.48,
                    },
                    1350: {
                        slidesPerView: 2.65,
                    },
                    1700: {
                        slidesPerView: 2.65,
                    },
                    1800: {
                        slidesPerView: 3.75,
                    }
                }}
            >
                {props.products && props.products.map((product, index) => (
                    <SwiperSlide key={index} className='swiper-slider-product-container'>
                        <Product productInfo={product} className='swiper-slider-product' />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};