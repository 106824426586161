// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

const AuthContext = createContext();

// Use a secure, randomly generated key. Store this securely!
const ENCRYPTION_KEY = process.env.REACT_APP_SERVER_ENDPOINT;

const encrypt = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
};

const decrypt = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, ENCRYPTION_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [adminId, setAdminId] = useState(null)

  useEffect(() => {
    const encryptedCookie = Cookies.get('localstore');
    if (encryptedCookie) {
      try {
        const decryptedData = decrypt(encryptedCookie);
        setIsAuthenticated(decryptedData.isAuthenticated);
      } catch (error) {
        console.error('Error decrypting cookie:', error);
        Cookies.remove('localstore');
      }
    }
  }, []);

  const login = async (username, password) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/admin/login`, {
        username,
        password
      });
      
      if (response.status === 200) {
        setIsAuthenticated(true);
        console.log(response.data.adminId)
        setAdminId(response.data.adminId)
        if (adminId) {
          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 1);

          const encryptedData = encrypt({
            isAuthenticated: true,
            adminId,
            expiresAt: expirationDate.toISOString()
          });
          Cookies.set('localstore', encryptedData, { expires: 1 }); // expires in 1 day
          // These line are for Debugging
          // const encryptedCookie = Cookies.get('localstore');
          // const decryptedData = decrypt(encryptedCookie);
          // console.log("Decripted",decryptedData)
        }
        return true;
      } else {
        console.error('Login failed:', response.data.message);
        return false;
      }
    } catch (error) {
      console.error('Error during login:', error);
      return false;
    }
  };

  const logout = async () => {

    try {
      const encryptedCookie = Cookies.get('localstore');
      if (encryptedCookie) {
        const cookieData = await decrypt(encryptedCookie)
        if (cookieData.adminId) {
          setAdminId(cookieData.adminId)
        }
      }
      
      const payload = {
        // Include adminId only if it exists
        ...(adminId ? { adminId } : {}),
      };
      
      const response = await axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/admin/logout`, payload);
      
      if (response.status === 200) {
        Cookies.remove('localstore');
        setIsAuthenticated(false);
        return true;
      } else {
        console.error('Logout failed:', response.data.message);
        return false;
      }
    } catch (error) {
      console.error('Error during logout:', error);
      return false;
    }
  };

  const getAdminId = async () => {
    const encryptedCookie = Cookies.get('localstore');
    if (encryptedCookie) {
      try {
        const decryptedData = decrypt(encryptedCookie);
        if (decryptedData.adminId) {
          return decryptedData.adminId;
        } else {
          console.log('admin data not found')
        }
      } catch (error) {
        console.error('Error decrypting cookie:', error);
        Cookies.remove('localstore');
      }
    }
  };

  const checkValidCookie = async () => {
    const encryptedCookie = Cookies.get('localstore');
    if (encryptedCookie) {
      try {
        const decryptedData = decrypt(encryptedCookie);
        
        if (decryptedData.adminId && decryptedData.expiresAt) {
          const currentDateTime = new Date();
          const expirationDateTime = new Date(decryptedData.expiresAt);
  
          if (currentDateTime < expirationDateTime) {
            return decryptedData;
          } else {
            console.log('Cookie has expired');
            await logout(); // Assuming you have a logout function
            Cookies.remove('localstore');
          }
        } else {
          console.log('Admin data or expiration date not found in cookie');
          await logout();
          Cookies.remove('localstore');
        }
      } catch (error) {
        console.error('Error decrypting cookie:', error);
        Cookies.remove('localstore');
        await logout();
      }
    } else {
      console.log('No cookie found');
      await logout();
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, getAdminId, checkValidCookie }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);