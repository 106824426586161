import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
// import "../styles/ProductPageRef.css"

// import Carousel from "react-multi-carousel";
import "../styles/ProductPage.css"

import '../styles/SliderProducts.css'
import Slider from "react-slick";
import SliderProjucts from './SliderProductPage';

const ProductHeader = ({ product }) => {
    const navigate = useNavigate();
    const imagePaths = product.imagePaths.split(',') // Skip the first 2 images
    const [currentImageIndex, setCurrentImageIndex] = useState(0); // Start from index 0

    useEffect(() => {
        imagePaths.forEach((path) => { // Preload all images
            const img = new Image();
            img.src = path;
        });
    }, [imagePaths]);

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) => {
            // Loop back to the last image if at the first image
            return prevIndex > 0 ? prevIndex - 1 : imagePaths.length - 1;
        });
    };

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => {
            // Loop back to the first image if at the last image
            return prevIndex < imagePaths.length - 1 ? prevIndex + 1 : 0;
        });
    };

    return (
        <div className='productheader-container'>
            <div className='productheader-content'>
                <div className='header-info'>
                    <div className='productheader-title-info'>
                        <h2 className='productheader-brand-header'>
                        {product?.brand?.name ? product.brand.name.toLowerCase() : ''}
                        </h2>
                        <h1>{product.title.toUpperCase()}</h1>
                        <p className='ref-number'>{product.refNumber}</p>
                        <p className='header-section-text'>CONTACT FOR PURCHASE</p>
                    </div>
                    {
                        window.innerWidth < 1100 && (
                            <div className='header-product'>
                                <div className='image-container'>
                                    <img 
                                        className='header-arrow-left' 
                                        src="/img/group-24-4.png" 
                                        alt="Previous" 
                                        onClick={prevImage} 
                                    />
                                    <img 
                                        src={imagePaths[currentImageIndex]} 
                                        alt="Product" 
                                        className='header-product-img'
                                    />
                                    <img 
                                        className='header-arrow-right' 
                                        src="/img/group-24-4.png" 
                                        alt="Next" 
                                        onClick={nextImage} 
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div>
                        <div className='product-btn-container'>
                            <div className='productheader-btn' >
                                <img className='productheader-btn-img' src="/img/Untitled_40_40.png" alt="" />
                                <a className='productheader-btn-a' href='mailto:contact@luxandco.com'>
                                    Contact By Email
                                </a>
                            </div>
                            <div className='productheader-btn' onClick={() => window.open('https://wa.me/61449992363?text=', '_blank')}>
                                <img className='productheader-btn-img' src="/img/Untitled_40_40.png" alt="" />
                                <p>Whatsapp Us</p>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    window.innerWidth > 1100 &&
                    (
                        <div className='header-product'>
                            <div className='image-container'>
                                <img 
                                    className='header-arrow-left' 
                                    src="/img/group-24-4.png" 
                                    alt="Previous" 
                                    onClick={prevImage} 
                                />
                                <img 
                                    src={imagePaths[currentImageIndex]} 
                                    alt="Product" 
                                    className='header-product-img'
                                    loading="lazy" 
                                />
                                <img 
                                    className='header-arrow-right' 
                                    src="/img/group-24-4.png" 
                                    alt="Next" 
                                    onClick={nextImage} 
                                />
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

const ProductDetails = (data) => {
    const product = data.product;
    
    const [selectedHeader, setSelectedHeader] = useState('General Info')

    const headers = [
        'General Info',
        'Description',
        'Delivery & Collection'
    ];

    return (
        <div className='productdetails-container'>
            <div className='productdetails-content'>
                <h1 className='productdetails-header'>DETAILS</h1>
                <div className='productdetails-menu'>
                    <div className='productdetails-menu-header'>
                    {headers.map(header => (
                        <p 
                            key={header} 
                            className={
                                selectedHeader === header 
                                ? 'productdetails-menu-header-option-selected' 
                                : 'productdetails-menu-header-option'
                            } 
                            onClick={() => setSelectedHeader(header)}
                        >
                            {header}
                        </p>
                    ))}
                    </div>
                    <div className="productdetails-menu-header-bar"/>
                    <div className='productdetails-menu-content'>
                        {
                            selectedHeader === 'General Info' && (
                                <div className="product-info">
                                    <div className="product-info-vertical">
                                        <p className="product-info-header">Box And Papers</p>
                                        <p className="product-info-data">{product.boxAndPapers === true? 'Included': 'Not Includes'}</p>
                                    </div>
                                    <div className="product-info-bar"/>
                                    <div className="product-info-vertical">
                                        <p className="product-info-header">Location</p>
                                        <p className="product-info-data">{product.location? product.location: ''}</p>
                                    </div>
                                    <div className="product-info-bar"/>
                                    <div className="product-info-vertical">
                                        <p className="product-info-header">Avalibility</p>
                                        <p className="product-info-data">{product.availability? 'In Stock': 'Out of Stock'}</p>
                                    </div>
                                    <div className="product-info-bar"/>
                                    <div className="product-info-vertical">
                                        <p className="product-info-header">Referance</p>
                                        <p className="product-info-data">{product.refNumber? product.refNumber: ''}</p>
                                    </div>
                                    <div className="product-info-bar"/>
                                    <div className="product-info-vertical">
                                        <p className="product-info-header">Year</p>
                                        <p className="product-info-data">{product.year? product.year: ''}</p>
                                    </div>
                                    <div className="product-info-bar"/>
                                    <div className="product-info-vertical">
                                        <p className="product-info-header">Model</p>
                                        <p className="product-info-data">{product.model && product.model.name? product.model.name: ''}</p>
                                    </div>
                                </div>
                            )
                        }
                        {
                            selectedHeader === 'Description' && (
                                <div className="product-info">
                                    <p className="product-info-description">
                                        {product.description}
                                    </p>
                                </div>
                            )
                        }
                        {
                            selectedHeader === 'Delivery & Collection' && (
                                <div className="product-info">
                                    <p className="product-info-description">
                                        To arrange collection or delivery, please contact us below
                                    </p>
                                    <div className='product-btn-container'>
                                        <div className='productheader-btn'>
                                            <img className='productheader-btn-img' src="/img/Untitled_40_40.png" alt="" />
                                            <a className='productheader-btn-a' href='mailto:contact@luxandco.com'>
                                                Contact By Email
                                            </a>
                                        </div>
                                        <div className='productheader-btn' onClick={() => window.open('https://wa.me/61449992363?text=', '_blank')}>
                                            <img className='productheader-btn-img' src="/img/Untitled_40_40.png" alt="" />
                                            <p>
                                                Whatsapp Us
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const Product = (props) => {
    const product = props.productInfo
    const imagePathsArray = product.imagePaths.split(',');
    const navigate = useNavigate();

    const handleSearchClick = () => {
            navigate(`/product/${product._id}`)
            window.location.reload();
            window.scrollTo(0, 0);
    };

    return (
        <div className='productpage-product' onClick={() => handleSearchClick()}>
            <div className='productpage-product-image'>
                <img src={imagePathsArray[1]} alt="Watch" />
            </div>
            <div className='productpage-product-discription'>
                <div className='productpage-product-header'>
                    <p>{product.brand.name}</p>
                    <p className='productpage-product-header-thin-margin'>{product.name}</p>
                </div>
                <p className='productpage-product-header-thin'>Reference Number: {product.refNumber}</p>
                <div className='productpage-product-footer'>
                    <img className='productpage-product-logo' src="/img/untitled-40-27.png" alt="Lux And Co" />
                    <p>Price On Request</p>
                </div>
            </div>
        </div>
    )
}

const OtherProducts = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderRef = useRef(null);
    const sliderContentRef = useRef(null);
    const [maxScroll, setMaxScroll] = useState(0);
    const navigate = useNavigate();
    const [sliderProducts, setSliderProducts] = useState([]);

    useEffect(() => {
        const updateMaxScroll = () => {
            if (sliderRef.current && sliderContentRef.current) {
                const containerWidth = sliderRef.current.offsetWidth;
                const contentWidth = sliderContentRef.current.scrollWidth;
                setMaxScroll(Math.max(0, contentWidth - containerWidth + 16));
            }
        };

        getRandomProducts();
        updateMaxScroll();
        window.addEventListener('resize', updateMaxScroll);

        return () => {
            window.removeEventListener('resize', updateMaxScroll);
        };
    }, []);

    const getRandomProducts = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/get/randomsliderproducts`)
        .then((r) => {
            setSliderProducts(r.data.products);
        });
    };

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => {
            const containerWidth = sliderRef.current.offsetWidth;
            const nextIndex = Math.min(prevIndex + containerWidth, maxScroll);
            return nextIndex;
        });
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => {
            const containerWidth = sliderRef.current.offsetWidth;
            return Math.max(prevIndex - containerWidth, 0);
        });
    };

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1400 },
          items: 3.5,
          partialVisibilityGutter: 20 // this is needed to tell the amount of px that should be visible.
        },
        tablet: {
          breakpoint: { max: 1300, min: 800 },
          items: 2.5
        },
        mobile: {
          breakpoint: { max: 800, min: 0 },
          items: 1.1
        }
    };    

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <img src="/img/group-24-4.png" alt="" className="slider-arrow-right" onClick={onClick}/>
        );
      }
      
      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <img src="/img/group-24-4.png" alt="" className="slider-arrow-left" onClick={onClick}/>
        );
    }
    

    const settings = {
        className: "slider variable-width",
        // dots: true,
        // infinite: true,
        // centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
      };

    return (
        <div className="OtherProducts">
            <div className='productpage-container'>
                <div className='productpage-header-text'>
                    <div className="productpage-subheader">more you</div>
                    <div className="productpage-header">MIGHT LIKE</div>
                    <p className="productpage-description">Discover the latest marvel in timekeeping technology: the ChronoMaster</p>
                    <div className='productpage-btn' onClick={() => navigate('/catalog')}>
                        <img className='productheader-btn-img' src="/img/Untitled_40_40.png" alt="" />
                        Browse all watches 
                    </div>
                </div>
                <div className='slider-control-container'>
                {sliderProducts.length > 0 ? (
                    // <Slider {...settings}>
                    //         { sliderProducts.length > 0 && sliderProducts.map((product, index) => (
                    //             <Product key={index} productInfo={product} />
                    //         ))}
                    // </Slider>
                    <SliderProjucts/>
                ):(
                    <div>
                        <h1>Loading...</h1>
                    </div>
                )}
                    {/* <div className='slider-overlay'>
                        <img 
                            src="/img/group-24-2.png" 
                            alt="slider arrow" 
                            className='arrow-left' 
                            onClick={prevSlide} 
                            style={{ opacity: currentIndex === 0 ? 0 : 1 }}
                        />
                        <img 
                            src="/img/group-24-2.png" 
                            alt="slider arrow" 
                            className='arrow-right' 
                            onClick={nextSlide} 
                            style={{ opacity: currentIndex >= maxScroll ? 0 : 1 }}
                        />
                    </div>
                    <div 
                        className='productpage-slider' 
                        ref={sliderContentRef}
                        style={{ transform: `translateX(-${currentIndex}px)` }}
                    >
                        { sliderProducts.length > 0 && sliderProducts.map((product, index) => (
                            <Product key={index} productInfo={product} />
                        ))}
                    </div> */}
                </div>
            </div>
        </div>
    );
};

const BuyThisWatch = () => {
    return (
        <div className='productpage-container-browse-more'>
            <div className='productdetails-buythiswatchz-content'>
                <h2 className='productdetails-buythiswatchz-subtitle'>LOOKING TO</h2>
                <h1 className='productdetails-buythiswatchz-title'>BUY THIS WATCH?</h1>
                <div className='productdetails-btn-container'>
                    <a href="href='mailto:contact@luxandco.com'" style={{textDecoration:'none', color:'inherit'}}>
                        <div className='productheader-btn'>
                            <img className='productheader-btn-img' src="/img/Untitled_40_40.png" alt="" />
                            Contact By Email
                        </div>
                    </a>
                    <div className='productheader-btn' onClick={() => window.open('https://wa.me/61449992363?text=', '_blank')}>
                        <img className='productheader-btn-img' src="/img/Untitled_40_40.png" alt="" />
                        Whatsapp Us
                    </div>
                </div>
            </div>
        </div>
    )
}

const ProductPage = () => {
    const { id } = useParams();
    const [product, setProduct] = useState()

    useEffect(() => {
        handleProductInfo()
    },[])

    const handleProductInfo = async () => {
        if (id) {
            await axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/get/productpage`, {
                params: {
                    productId: id
                }
            }).then((r) => {
                setProduct(r.data.product)
            })
        }
    }

    return (
        <div>
            <div style={{opacity:'0.1', position:'absolute'}}>
                {/* <ProductPageRef/> */}
            </div>
            <div className="header-container-cat-page">
                <Header backgroundColor='#EDF0F4'/>
            </div>
            {
                product? 
                    <div className='product-page-gloal'>
                        <ProductHeader product={product}/>
                        <ProductDetails product={product}/>
                        <OtherProducts/>
                        <BuyThisWatch/>
                    </div>
                 : 
                    <div>
                        <p>Loading...</p>
                    </div>
                
            }
            <Footer/>
        </div>
    );
}
 
export default ProductPage;