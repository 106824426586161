import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Header from "./components/Header"
import Footer from './components/Footer'
import "./Sell-Consign-Desktop.css";
import "./Sell-Consign-Mobile.css";
import "./styles/SellConsignPage.css"
import TextAnimation from './components/TextAnimation';
import { useNavigate } from 'react-router-dom';

const VerticalProgressBar = ({ value }) => {
  return (
    <div className='progress-bar-container'>
      <div className="progress-bar-content">
        <div
          className="progress-bar"
          style={{ height: `${value}%`, top: 0 }}
        ></div>
      </div>
        <div className='progress-point progress-point-start'>
          <div className='progress-point-color' id='progress-point'>
            
          </div>
        </div>
        <div className='progress-point progress-point-center'>
          <div className='progress-point-color' id='progress-point2'>
            
          </div>
        </div>
        <div className='progress-point progress-point-end'>
          <div className='progress-point-color' id='progress-point3'>
            
          </div>
        </div>
    </div>
  );
};

const BookingSection = () => {
  const navigate = useNavigate();

  const handleOpenNewTab = () => {
    window.open('https://wa.me/61449992363?text=', '_blank', 'noopener,noreferrer');
  };

  return (
    <div className='sell-book-container'>
      <div className='sell-book-content'>
        <h2 className='sell-subheader-text-dark'>book your</h2>
        <h1 className='sell-header-text-dark-valuation-desktop'>FREE VALUATION TODAY</h1>
        <h1 className='sell-header-text-dark-valuation-mobile'>FREE VALUATION</h1>
        <div className='sell-btn-container'>
          <div className='sell-book-btn-container-outline' onClick={() => navigate('/')}>
            <img className="sell-btn-img-outline" src="/img/untitled-40-36.png" alt=""/>
            Back To Home
          </div>
          <div className='sell-book-btn-container-outline' onClick={handleOpenNewTab}>
            <img className="sell-btn-img-outline" src="/img/untitled-40-36.png" alt=""/>
            Whatsapp Us
          </div>
        </div>
      </div>
    </div>
  )
}

const SellConsignPage = () => {
  const [currentPercentage, setCurrentPercentage] = useState(0)
  const navigate = useNavigate();

  const handleScroll = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const threshold0 = 1; // Adjust this value to your desired scroll amount
    const threshold = 1350; // Adjust this value to your desired scroll amount
    const threshold2 = 2000; // Adjust this value to your desired scroll amount

    const progressPoint = document.getElementById('progress-point'); // Target the div
    const progressPoint2 = document.getElementById('progress-point2'); // Target the div
    const progressPoint3 = document.getElementById('progress-point3'); // Target the divz

    if (scrollTop-100 < threshold) {
      progressPoint.className = 'progress-point-color'; // Set the class when scroll position is above threshold2
      progressPoint2.className = 'progress-point-color'; // Set the class when scroll position is above threshold2
      setCurrentPercentage(0)
      // Your custom function or action here
    }

    if (scrollTop > threshold0) {
      progressPoint.className = 'progress-point-color-dark'; // Set the class when scroll position is above threshold2
      setCurrentPercentage(0)
      // Your custom function or action here
    }

    if (scrollTop > threshold) {
      setCurrentPercentage(38)
      progressPoint2.className = 'progress-point-color-dark';
      progressPoint3.className = 'progress-point-color';
      // Your custom function or action here
    }
    
    if (scrollTop > threshold2 && scrollTop) {
      setCurrentPercentage(100)
      progressPoint3.className = 'progress-point-color-dark';
      // Your custom function or action here
    }
  };

  useEffect(() => {
    // Add event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const customVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1, // Adjust the duration as needed
        ease: 'easeInOut'
      }
    }
  };

  return (
    <div className="sell-page-container">
      <div style={{ position: 'absolute', opacity: 1, zIndex: 10, top: 0, left: 0 }}>
        {/* <SellConsign/> */}
      </div>
        <div className='sell-header-controle'>
          <Header theme='light'/>
        </div>
      <div className="sell-header">
        <div className="sell-header-content">
          <h2 className="sell-subheader-text">discover</h2>
          <h1 className="sell-header-text">THE PROCESS</h1>
          <p className="sell-header-description">
            Experience our streamlined service for selling luxury watches. Our process is efficient,
            ensuring a professional experience.
          </p>
          <div className="sell-header-btn" style={{cursor:'pointer'}}>
            <img className="sell-btn-img" src="/img/untitled-40-37.png" alt=""/>
            Back To Home
          </div>
        </div>
      </div>
      <div className="sell-progrss-section">
        <div className="sell-progrss-container">
          <div className="sell-progrss-content">
            <div className='sell-progrss-images'>
              <TextAnimation animationVariants={customVariants}>
                <img src="/img/untitled-52-1.png" alt="" className="sell-progrss-img"/>
              </TextAnimation>
              <TextAnimation animationVariants={customVariants} delay={0.3}>
                <img src="/img/untitled-56-1.png" alt="" className="sell-progrss-img1"/>
              </TextAnimation>
              <TextAnimation animationVariants={customVariants}>
                <img src="/img/untitled-59-1.png" alt="" className="sell-progrss-img2"/>
              </TextAnimation>
            </div>
            <VerticalProgressBar value={currentPercentage}/>
            <div className='sell-progrss-info'>
              <TextAnimation animationVariants={customVariants}>
              <div className='sell-progrss-infosection-one'>
                <h2 className='sell-progress-subheader'>step 1</h2>
                <h1 className='sell-progress-header'>VALUATION</h1>
                <p className='sell-progress-description'>
                  During this step, we inspect your watch
                  and give you an on the spot valuation.
                  Once evaluated, we will make you
                  an offer on the piece.
                </p>
                <div className="sell-header-btn" onClick={() => navigate('/')}>
                  <img className="sell-btn-img-black" src="/img/untitled-40-36.png" alt=""/>
                  Back To Home
                </div>
              </div>
              </TextAnimation>
              <TextAnimation animationVariants={customVariants} delay={0.3}>
                <div className='sell-progrss-infosection-two'>
                    <h2 className='sell-progress-subheader'>step 2</h2>
                    <h1 className='sell-progress-header'>VALIDATION</h1>
                    <p className='sell-progress-description'>
                      Upon confirming the offer given, we will have
                      the watch verified by one of our partners.
                      Your watch will be shipped via a secure,
                      insured delivery service.
                    </p>
                    <div className="sell-header-btn" onClick={() => navigate('/')}>
                      <img className="sell-btn-img-black" src="/img/untitled-40-36.png" alt=""/>
                      Back To Home
                    </div>
                </div>
              </TextAnimation>
              <TextAnimation animationVariants={customVariants} delay={0.3}>
                <div className='sell-progrss-infosection-three'>
                    <h2 className='sell-progress-subheader'>step 3</h2>
                    <h1 className='sell-progress-header'>PAYMENT</h1>
                    <p className='sell-progress-description'>
                      Once your watch has been verified, 
                      you will receive an instant payment 
                      via bank wire transfer.
                    </p>
                    <div className="sell-header-btn" onClick={() => navigate('/')}>
                      <img className="sell-btn-img-black" src="/img/untitled-40-36.png" alt=""/>
                      Back To Home
                    </div>
                </div>
              </TextAnimation>
            </div>
          </div>
        </div>
      </div>
      <BookingSection/>
      <Footer />
    </div>
  );
};

export default SellConsignPage;
