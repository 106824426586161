import React, { useState } from 'react';
import Header from "./Header"
import Footer from "./Footer"
import "../styles/aboutpage.css"

const AboutPage = () => {
    const [message, setMessage] = useState('');

    const handleSendMessage = () => {
        const whatsappUrl = `https://wa.me/61449992363?text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, '_blank');
    };

    return (
        <div style={{position:'relative'}}>
            <div className='sell-header-controle' style={{zIndex:1000}}>
                <Header theme='light'/>
            </div>
    <div className="about-hero">
        <div className="about-hero-container">
            <div className="arrow-down-landing">
                <div className="w-embed">
                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="28" cy="28" r="27.5" stroke="white" strokeOpacity="0.3" />
                        <path d="M34 28L32.9425 26.9425L28.75 31.1275V22H27.25V31.1275L23.065 26.935L22 28L28 34L34 28Z" fill="white" />
                    </svg>
                </div>
            </div>
            <div className="div-block-12">
                <h1 className="heading1 light about">Swiss precision, unparalleled style</h1>
                <p className="paragraph-white about">We are a worldwide provider of luxury Swiss Timepieces, based out of Australia, Dubai, and Hong Kong. We dedicate ourselves to building an unparalleled global network of category specialists capable of securing the most coveted pieces
                    from the world's leading makers.</p>
            </div>
        </div>
    </div>
    <div className="col-about-sec">
        <div className="col-about-con about-page">
            <p className="about-seperation-headline about-page">Underpinned by the belief that anything is possible, lux&amp;co was established to provide access to a life desired. Through action and truth, we seek to deliver an engagement with luxury that prioritises AN extraordinary customer experience.</p>
            <img src="https://cdn.prod.website-files.com/63b2e1cb4d1e9e6121718afa/63b9aa9ea5f15f6838637478_image%20180.png" loading="lazy" alt="" className="image-14" />
        </div>
    </div>
    <div className="section">
        <div className="at-lux-aboutus">
            <div id="w-node-_196a4d1c-bd85-619e-e268-0eb535139474-6759865c" className="abt-sec-con-s">
                <div className="at-lux-txt">
                    <div className="text-container">
                        <h1 className="heading-28">AT LUX &amp; CO</h1>
                        <p className="paragraph-dark about">We are committed to helping our esteemed customers achieve their aspirations of luxury and sophistication. We pride ourselves on providing exceptional customer service and upholding the highest standards of excellence. We look
                            forward to assisting you in finding the perfect item to enhance your lifestyle. Our business is dedicated  to building an unparalleled global network of category specialists capable of securing the most coveted pieces from
                            the world's leading makers.
                        </p>
                    </div>
                    <div className="watch-container"><img src="https://cdn.prod.website-files.com/63b2e1cb4d1e9e6121718afa/63e8da66f17305482705fb4b_63cc8382f1e5b448ff9e9dec_justwatch-removebg-preview.png" loading="lazy" alt="" className="image-15" /></div>
                </div>
            </div>
            <div className="lux-hero"><img src="https://cdn.prod.website-files.com/63b2e1cb4d1e9e6121718afa/63cef3198770f768f2377fed_atluxandco.png" loading="lazy" alt="" className="image-11" /></div>
        </div>
    </div>
    <Footer/>
    </div>
    );
}
 
export default AboutPage;