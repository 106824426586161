import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BrandSlider from './SliderBrandsLarge'
import { useNavigate } from 'react-router-dom';
import TextAnimation from './TextAnimation'
import '../styles/FullScreenDropDown.css';

function MegaMenuHeader(props) {
  const navigate = useNavigate();

  return (
    <div className='megamenu-header-container'>
      <div className='megamenu-header-content'>
        <div style={{cursor:'pointer', height:'35px',width:'35px', zIndex:100}} onClick={() => {props.onClose()}}>
          <img src="/img/burger.png" alt="" className='megamenu-function-image' />
        </div>
        <img src="/img/lux-co-logo-99.png" alt="" style={{zIndex:100, cursor:'pointer'}} className='megamenu-logo-image' onClick={() => {
          navigate('/',{ replace: true })
          props.onClose()
        }}/>
        <img src="/img/search.png" alt="" style={{zIndex:100, cursor:'pointer'}} className='megamenu-function-image' onClick={() => {
          navigate('/catalog',{ replace: true })
          props.onClose()
        }}/>
      </div>
    </div>
  );
}

const ChevronIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="9 18 15 12 9 6" />
  </svg>
);

const VerticalDropdown = ({ title, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (link) => {
      navigate(link,{ replace: true });
      window.location.reload();
  }

  return (
    <div className="dropdown-selector">
      <button
        onClick={toggleDropdown}
        className="dropdown-header"
      >
        <span className='dropdown-title'>{title}</span>
        <ChevronIcon
          className={isOpen? "dropdown-arrow-open":"dropdown-arrow"}
        />
      </button>
      {isOpen && (
        <div className="dropdown-options">
          {options.map((option, index) => (
            <button
              key={index}
              className="dropdown-option"
              onClick={() => handleClick(option.link)}
            >
              {option.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

const MegaMenuSideBar = (props) => {
  const navigate = useNavigate();
  const [brands, setBrands] = useState({})
  const [options2, setOptions2] = useState([]);
  const {onClose} = props
  
  const getBrands = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/get/brands`);
      if (response.data.data) {
        const fetchedBrands = response.data.data;
        // Assuming fetchedBrands is an array of brand objects
        const formattedBrands = fetchedBrands.map(brand => ({
          name: brand.name,
          link: `/catalog?brand=${brand._id}` // Example link format with query parameters
        }));
        setBrands(formattedBrands);

        // Update options2 with new brands
        setOptions2(prevOptions => [
          ...prevOptions,
          ...formattedBrands
        ]);
      }
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };
  
  useEffect(() => {
    getBrands()
  },[])

  const options1 = [{
    name: 'Browse All Watches',
    link: '/catalog'
  },{
    name: 'Sell Your Watches',
    link: ''
  }];
  
  const options3 = [{
    name: 'About Us',
    link: '/about'
  }];
  
  const options4 = [{
    name: 'About Cookie Policy',
    link: ''
  },{
    name: 'Return Policy',
    link: ''
  },{
    name: 'Terms & Conditions',
    link: ''
  },];

  const customVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5, // Adjust the duration as needed
        ease: 'easeInOut'
      }
    }
  };

  return (
    <div className='mega-menu-sidebar-conatiner'>
      <div className='mega-menu-sidebar-contant'>
        <div className='mega-menu-sidebar-dropdown' style={{color:'#333333'}}>
          <TextAnimation animationVariants={customVariants} delay={0.1}>
            <h1 className='mega-menu-header'>WATCHES</h1>
          </TextAnimation>
          <TextAnimation animationVariants={customVariants} delay={0.1}>
            <p onClick={() => navigate('/catalog', { replace: true })}>Browse All Watches</p>
            <p onClick={() => navigate('/sellconsign', { replace: true })}>Sell Your Watch</p>
          </TextAnimation>
            
        </div>
        <div className='mega-menu-sidebar-dropdown'>
            <TextAnimation animationVariants={customVariants} delay={0.1}>
              <h1 className='mega-menu-header'>COLLECTIONS</h1>
            </TextAnimation>
            <TextAnimation animationVariants={customVariants} delay={0.2}>
              {
                brands && brands.length > 0 && brands.map(brand => {
                  console.log(brand)
                  return (
                    <p onClick={() => {
                      navigate(brand.link,{ replace: true });
                      window.location.reload();
                      onClose()
                    }} style={{color:'#333333'}}>{brand.name}</p>
                  )
                } )
              }
            </TextAnimation>
        </div>
        <div className='mega-menu-sidebar-dropdown'>
            <TextAnimation animationVariants={customVariants} delay={0.1}>
              <h1 className='mega-menu-header'>LUX & CO</h1>
            </TextAnimation>
            <TextAnimation animationVariants={customVariants} delay={0.2}>
              <p onClick={() => navigate('/about', { replace: true })} style={{color:'#333333'}}>About Us</p>
            </TextAnimation>
        </div>
        <div className='mega-menu-sidebar-dropdown' style={{color:'#333333'}}>
            <TextAnimation animationVariants={customVariants} delay={0.1}>
              <h1 className='mega-menu-header'>LEGAL</h1>
            </TextAnimation>
            <TextAnimation animationVariants={customVariants} delay={0.2}>
              <p>Cookie Policy</p>
              <p>Return Policy</p>
              <p>Terms & Conditions</p>
            </TextAnimation>
        </div>
      </div>
      <div className='dd-model-content'>
        <VerticalDropdown title="WATCHES" options={options1}/>
        <VerticalDropdown title="COLLECTIONS" options={options2}/>
        <VerticalDropdown title="LUX & CO" options={options3}/>
        <VerticalDropdown title="LEGAL" options={options4}/>
      </div>
    </div>
  )
}

const MegaMenuContent = (props) => {
  const { onClose } = props

  const customVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5, // Adjust the duration as needed
        ease: 'easeInOut'
      }
    }
  };

  return (
    <div className='mega-menu-content-container'>
      <div className='mega-menu-content-content'>
        <div className='mega-menu-content-controle'>
          <div className='menu-mega-brand-slider-container'>
            <div className='mega-menu-header-container'>
              <TextAnimation animationVariants={customVariants} delay={0.1}>
                <h2 className='mega-menu-content-subheader'>discover</h2>
              </TextAnimation>
              <TextAnimation animationVariants={customVariants} delay={0.2}>
                <h1 className='mega-menu-content-header'>BRANDS</h1>
              </TextAnimation>
            </div>
            <div className='mega-menu-slider-control'>
            <TextAnimation animationVariants={customVariants} delay={0.3}>
                <BrandSlider onClose={onClose}/>
              </TextAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const MegaMenuContainer = (props) => {
  return (
    <div className='mega-menu-outterlayer'>
      <MegaMenuSideBar onClose={props.onClose}/>
      <MegaMenuContent onClose={props.onClose}/>
    </div>
  )
}

const FullScreenDropDown = ({ isOpen, onClose, children }) => {
    const [animationClass, setAnimationClass] = useState('');

    useEffect(() => {
      if (isOpen) {
        setAnimationClass('fsd-slide-down');
        document.body.style.overflow = 'hidden';
      } else {
        setAnimationClass('fsd-slide-up');
        document.body.style.overflow = '';
      }
  
      return () => {
        document.body.style.overflow = '';
      };
    }, [isOpen]);

    if (!isOpen && animationClass !== 'fsd-slide-down') {
        return null;
    }

  return (
    <div className={`full-screen-dropdown ${animationClass}`}>
      <div className="fsd-content">
        <MegaMenuHeader onClose={onClose}/>
        {/* <button 
          onClick={onClose}
          className="fsd-close-btn"
        >
          &times;
        </button> */}
        {children}
        <MegaMenuContainer onClose={onClose}/>
      </div>
    </div>
  );
};

export default FullScreenDropDown;