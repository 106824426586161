import React, { useEffect, useState ,useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import AdminProductPage from './AdminProductPage'
import axios from 'axios';
import Fuse from 'fuse.js';
import Cookies from 'js-cookie';
import '../styles/AdminOverview.css';
import { Toaster, toast } from 'sonner'

const AdminOverview = () => {
    const navigate = useNavigate();

    const { isAuthenticated, logout, getAdminId, checkValidCookie } = useAuth();
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const [brands, setBrands] = useState({})
    const [filteredbrands, setFilteredBrands] = useState({})

    const [brandModels, setBrandModels] = useState({})
    const [filteredBrandModels, setFilteredBrandModels] = useState({})

    const [complications, setComplications] = useState({})
    const [filteredComplications, setFilteredComplications] = useState({})

    const [size, setSize] = useState({})
    const [filteredSize, setFilteredSize] = useState({})

    const [products, setProducts] = useState([])
    const [filteredProducts, setFilteredProducts] = useState([])

    const [functions, setFunctions] = useState([])
    const [filteredFunctions, setFilteredFunctions] = useState([])

    const [searchTerm, setSearchTerm] = useState('')
    const [fuse, setFuse] = useState(null);

    const [isFullScreen, setIsFullScreen] = useState(false);

    const [fuseInstances, setFuseInstances] = useState({
        products: null,
        brands: null,
        brandModels: null,
        complications: null,
        size: null,
        functions: null
    });

    useEffect(() => {
        if (isAuthenticated === false || isAuthenticated === null) {
            navigate('/dashboardsignin')
        }
        checkValidCookie()
    },[])

    useEffect(() => {
        const newFuseInstances = {};
      
        if (products.length > 0) {
          newFuseInstances.products = new Fuse(products, {
            keys: ['name', 'description'],
            threshold: 0.3,
          });
        }
      
        if (Object.keys(brands).length > 0) {
          newFuseInstances.brands = new Fuse(Object.values(brands), {
            keys: ['name'], // adjust as needed
            threshold: 0.3,
          });
        }
      
        if (Object.keys(brandModels).length > 0) {
          newFuseInstances.brandModels = new Fuse(Object.values(brandModels), {
            keys: ['name'], // adjust as needed
            threshold: 0.3,
          });
        }
      
        if (Object.keys(complications).length > 0) {
          newFuseInstances.complications = new Fuse(Object.values(complications), {
            keys: ['name'], // adjust as needed
            threshold: 0.3,
          });
        }
      
        if (Object.keys(size).length > 0) {
          newFuseInstances.size = new Fuse(Object.values(size), {
            keys: ['name'], // adjust as needed
            threshold: 0.3,
          });
        }
      
        if (functions.length > 0) {
          newFuseInstances.functions = new Fuse(functions, {
            keys: ['name'], // adjust as needed
            threshold: 0.3,
          });
        }
      
        setFuseInstances(newFuseInstances);
      }, [products, brands, brandModels, complications, size, functions]);

      const performSearch = useCallback(async () => {
        try {
          if (searchTerm === '') {
            // Reset all filtered states to their original states
            setFilteredProducts(products);
            setFilteredBrands(brands);
            setFilteredBrandModels(brandModels);
            setFilteredComplications(complications);
            setFilteredSize(size);
            setFilteredFunctions(functions);
            return;
          }
      
          // Perform search for each collection
          if (fuseInstances.products && selectedCollection === 'products') {
            const results = fuseInstances.products.search(searchTerm).map(result => result.item);
            setFilteredProducts(results.reverse());
          }
      
          if (fuseInstances.brands && selectedCollection === 'brands') {
            const results = fuseInstances.brands.search(searchTerm).map(result => result.item);
            console.log('res',results)
            setFilteredBrands(results.reverse());
            console.log('FilteredBrnds',filteredbrands)
          }
      
          if (fuseInstances.brandModels && selectedCollection === 'brandModels') {
            const results = fuseInstances.brandModels.search(searchTerm).map(result => result.item);
            setFilteredBrandModels(results.reverse());
          }
      
          if (fuseInstances.complications && selectedCollection === 'complications') {
            const results = fuseInstances.complications.search(searchTerm).map(result => result.item);
            setFilteredComplications(results.reverse());
          }
      
          if (fuseInstances.size && selectedCollection === 'size') {
            const results = fuseInstances.size.search(searchTerm).map(result => result.item);
            setFilteredSize(results.reverse());
          }
      
          if (fuseInstances.functions && selectedCollection === 'functions') {
            const results = fuseInstances.functions.search(searchTerm).map(result => result.item);
            setFilteredFunctions(results.reverse());
          }
        } catch (error) {
          console.error('Error during search:', error);
        }
      }, [searchTerm, selectedCollection, fuseInstances, products, brands, brandModels, complications, size, functions]);

      const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            performSearch();
        }
      };


    if (isAuthenticated === false || isAuthenticated === null) {
        navigate('/dashboardsignin')
    }

    const collections = [
        { id: 'complications', name: 'Complications' },
        { id: 'size', name: 'Case Sizes' },
        { id: 'brands', name: 'Brands' },
        { id: 'products', name: 'Products' },
        { id: 'models', name: 'Models' },
        { id: 'functions', name: 'Functions' }
    ];

    const handleCollectionSelect = (collectionId) => {
        console.log('handleSelect')
        if (collectionId){
            setSelectedCollection(collectionId);
        }
        if (collectionId === 'brands') {
            getBrands()
            console.log(brands)
        } else if (collectionId === 'complications') {
            getComplication()
        } else if (collectionId === 'size') {
            getSizes()
        } else if (collectionId === 'products') {
            getProducts()
        }
        setSelectedItem(null);
    };

    const handleItemSelect = (item) => {
        setSelectedItem(item);
        setIsFullScreen(true)
    };

    const closeItemModal = () => {
        setSelectedItem(null);
    };

    const updateButtonStyle = {
        backgroundColor: '#4CAF50', // Green background
        border: 'none',
        color: 'white',
        padding: '10px 20px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: '16px',
        margin: '4px 2px',
        cursor: 'pointer',
        borderRadius: '4px'
      };
      
      const deleteButtonStyle = {
        backgroundColor: '#f44336', // Red background
        border: 'none',
        color: 'white',
        padding: '10px 20px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: '16px',
        margin: '4px 2px',
        cursor: 'pointer',
        borderRadius: '4px'
      };

    const getBrands = async () => {
        axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/get/brands`).then(r => {
            console.log(r)
            setBrands(r.data.data)
            setFilteredBrands(r.data.data)
        })
    }
    
    const deleteBrand = async (item) => {
        const adminId = await getAdminId()
        axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/admin/remove/brand`,{
            brandId: item._id,
            adminId
        }).then(r => getBrands())
    }

    const getComplication = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/get/complications`).then(r => {
            console.log(r)
            setComplications(r.data.data)
            setFilteredComplications(r.data.data)
        })
    }

    const deleteComplication = async (item) => {
        const adminId = await getAdminId()
        axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/admin/remove/complication`,{
            complicationId: item._id,
            adminId
        }).then(r => getComplication())
    }

    const getSizes = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/get/size`).then(r => {
            console.log(r)
            setSize(r.data.data)
            setFilteredSize(r.data.data)
        })
    }

    const deleteSizes = async (item) => {
        const adminId = await getAdminId()
        axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/admin/remove/size`,{
            sizeId: item._id,
            adminId
        }).then(r => getSizes())
    }

    const getProducts = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/get/products`).then(r => {
            console.log(r)
            setProducts(r.data.data)
            setFilteredProducts(r.data.data)
        })
    }
    
    const getBrandModels = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/get/brandmodels`).then(r => {
            console.log(r)
            setBrandModels(r.data.data)
            setFilteredBrandModels(r.data.data)
        })
    }
    
    const getFunctionsModels = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/functions/get`).then(r => {
            const foundFunctions = r.data.functions
            console.log (r)
            console.log('found functions',foundFunctions)
            setFunctions(foundFunctions)
            setFilteredFunctions(foundFunctions)
        })
    }

    const deleteProduct = async (item) => {
        const adminId = await getAdminId()
        axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/admin/remove/product`,{
            productId: item._id,
            adminId
        }).then(r => getSizes())
    }
    
    const deleteBrandModel = async (item) => {
        const adminId = await getAdminId()
        axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/admin/remove/brandmodel`,{
            brandmodelId: item._id,
            adminId
        }).then(r => getSizes())
    }
    
    const deleteFunctionModel = async (item) => {
        const adminId = await getAdminId()
        axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/function/admin/delete`,{
            functionId: item._id,
            adminId
        }).then(r => getFunctionsModels())
    }

    const ImagePreview = ({ imagePaths }) => {
        // Split the imagePaths string into an array
        const imagePathArray = imagePaths ? imagePaths.split(',') : [];
        
        // Get the first path from the array
        const firstImagePath = imagePathArray[0] || ''; // Default to an empty string if no paths
      
        return (
          <div>
            {/* Display the image preview if the path exists */}
            {firstImagePath && (
              <img src={firstImagePath} alt="Image Preview" style={{ maxWidth: '300px' }} />
            )}
          </div>
        );
      };

    return (
        <div className="admin-overview">
            <Toaster richColors />
            <div style={{display:'flex',justifyContent:'space-between', marginBottom:'2rem'}}>
                <div style={{display:'flex', alignItems:'center'}}>
                    <img style={{height:'25px', width:'auto', marginRight:'1rem'}} src="/img/Untitled_40_40.png" alt=""/>
                    <div>
                        <h1 className="admin-overview__title">Admin Dashboard</h1>
                        <p className="admin-overview__description">Manage products and collections</p>
                    </div>
                </div>
                <p style={{color:'#4299E1',cursor:'pointer'}} onClick={() => logout()}>logout</p>
            </div>
            <div className="admin-overview__grid">
                {collections.map((collection) => (
                    <div key={collection.id} className="admin-overview__card">
                        <div className="admin-overview__card-header">
                            <h2 className="admin-overview__card-title">{collection.name}</h2>
                        </div>
                        <div className="admin-overview__card-content">
                            <button 
                                onClick={() => handleCollectionSelect(collection.id)}
                                className="admin-overview__button"
                            >
                                Manage {collection.name}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            {
                selectedCollection &&
                <div className='admin-searchbar-container'>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                    <input 
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={handleKeyPress}
                    style={{
                        padding: '8px',
                        fontSize: '16px',
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                        width: '400px',
                        outline: 'none'
                    }}
                        placeholder="Search"
                    />
                        <AdminProductPage
                            isFullScreen={isFullScreen}
                            setIsFullScreen={setIsFullScreen}
                            selectedCollection={selectedCollection}
                            setSelectedItem={setSelectedItem}
                            selectedItem={selectedItem}
                            getProducts={getProducts}
                            getBrands={getBrands}
                            getComplication={getComplication}
                            getSizes={getSizes}
                            getBrandModels={getBrandModels}
                            getFunctions={getFunctionsModels}
                            sizes={size}
                            complications={complications}
                            brands={brands}
                            products={products}
                            brandModels={brandModels}
                            functions={functions}
                            toast={toast}
                        />
                    </div>
                </div>
            }
            {selectedCollection && (
                <div className="admin-overview__selected">
                    <h2 className="admin-overview__selected-title">Selected Collection: {selectedCollection}</h2>
                    <div className="admin-overview__items">
                        {selectedCollection === 'brands' && filteredbrands.length > 0 && filteredbrands.map((item) => (
                            <div key={item.id} className="admin-overview__item-card" >
                                {
                                    console.log('item',item)
                                }
                                <h3 className="admin-overview__item-name">{item.name}</h3>
                                <div styles={{display:'flex',justifyContent:'space-around'}}>
                                    <button style={updateButtonStyle} onClick={() => handleItemSelect(item)}>Update</button>
                                    <button style={deleteButtonStyle} onClick={() => {
                                        deleteBrand(item)
                                    }}>Delete</button>
                                </div>
                            </div>
                        )).reverse()}
                        {selectedCollection === 'complications' && filteredComplications.length > 0 && filteredComplications.map((item) => (
                            <div key={item.id} className="admin-overview__item-card" >
                                {
                                    console.log('item',item)
                                }
                                <h3 className="admin-overview__item-name">{item.name}</h3>
                                <div styles={{display:'flex',justifyContent:'space-around'}}>
                                    <button style={updateButtonStyle} onClick={() => handleItemSelect(item)}>Update</button>
                                    <button style={deleteButtonStyle} onClick={() => {
                                        deleteComplication(item)
                                    }}>Delete</button>
                                </div>
                            </div>
                        )).reverse()}
                        {selectedCollection === 'size' && filteredSize.length > 0 && filteredSize.map((item) => (
                            <div key={item.id} className="admin-overview__item-card" >
                                <h3 className="admin-overview__item-name">{item.name}</h3>
                                <div styles={{display:'flex',justifyContent:'space-around'}}>
                                    <button style={updateButtonStyle} onClick={() => handleItemSelect(item)}>Update</button>
                                    <button style={deleteButtonStyle} onClick={() => {
                                        deleteSizes(item)
                                    }}>Delete</button>
                                </div>
                            </div>
                        )).reverse()}
                        {selectedCollection === 'products' && filteredProducts.length > 0 && filteredProducts.map((item) => (
                            <div key={item.id} className="admin-overview__item-card" >
                                <ImagePreview imagePaths={item.imagePaths}/>
                                <h3 className="admin-overview__item-name">{item.name}</h3>
                                <div styles={{display:'flex',justifyContent:'space-around'}}>
                                    <button style={updateButtonStyle} onClick={() => handleItemSelect(item)}>Update</button>
                                    <button style={deleteButtonStyle} onClick={() => {
                                        deleteProduct(item)
                                    }}>Delete</button>
                                </div>
                            </div>
                        )).reverse()}
                        {selectedCollection === 'models' && filteredBrandModels.length > 0 && filteredBrandModels.map((item) => (
                            <div key={item.id} className="admin-overview__item-card" >
                                <ImagePreview imagePaths={item.imagePaths}/>
                                <h3 className="admin-overview__item-name">{item.name}</h3>
                                <div styles={{display:'flex',justifyContent:'space-around'}}>
                                    <button style={updateButtonStyle} onClick={() => handleItemSelect(item)}>Update</button>
                                    <button style={deleteButtonStyle} onClick={() => {
                                        deleteBrandModel(item)
                                    }}>Delete</button>
                                </div>
                            </div>
                        )).reverse()}
                        {selectedCollection === 'functions' && filteredFunctions && filteredFunctions.length > 0 && filteredFunctions.map((item) => (
                            <div key={item.id} className="admin-overview__item-card" >
                                <ImagePreview imagePaths={item.imagePaths}/>
                                <h3 className="admin-overview__item-name">{item.name}</h3>
                                <div styles={{display:'flex',justifyContent:'space-around'}}>
                                    <button style={updateButtonStyle} onClick={() => handleItemSelect(item)}>Update</button>
                                    <button style={deleteButtonStyle} onClick={() => {
                                        deleteFunctionModel(item)
                                    }}>Delete</button>
                                </div>
                            </div>
                        )).reverse()}
                    </div>
                </div>
            )}
            {/* {selectedItem && (
                <div className="admin-overview__modal-overlay" onClick={closeItemModal}>
                    <div className="admin-overview__modal-content" onClick={(e) => e.stopPropagation()}>
                        <h2 className="admin-overview__modal-title">{selectedItem.name}</h2>
                        <p><strong>Description:</strong> {selectedItem.description}</p>
                        {selectedItem.price && <p><strong>Price:</strong> {selectedItem.price}</p>}
                        {selectedItem.stock && <p><strong>Stock:</strong> {selectedItem.stock}</p>}
                        {selectedItem.brand && <p><strong>Brand:</strong> {selectedItem.brand}</p>}
                        {selectedItem.category && <p><strong>Category:</strong> {selectedItem.category}</p>}
                        {selectedItem.caseSize && <p><strong>Case Size:</strong> {selectedItem.caseSize}</p>}
                        <button className="admin-overview__modal-close" onClick={closeItemModal}>Close</button>
                    </div>
                </div>
            )} */}
        </div>
    );
};

export default AdminOverview;