import React, { useState, useEffect, useRef } from 'react';
import Carousel from "react-multi-carousel";
import Slider from "react-slick";
import "react-multi-carousel/lib/styles.css";
import '../styles/BrandSliderLarge.css'
import { useNavigate } from 'react-router-dom';

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/navigation';

const Brand = (props) => {
    const navigate = useNavigate();

    const handleClick = () => {
        const queryParams = new URLSearchParams({
          brand: props.productInfo._id,
        }).toString();
    
        if (props.onClose) {
            props.onClose()
        }
        
        window.location.href = `/catalog?${queryParams}`;
    };

    return (
        <div className='large-brand' onClick={handleClick}>
            <div className='large-brand-overlay'>

            </div>
            <div className='large-brand-image'>
                <img src={props.productInfo.img} alt="Watch" />
            </div>
            <div className='large-brand-discription'>
                <div className='large-brand-header'>
                    <p>{props.productInfo.brand.toUpperCase()}</p>
                </div>
                <div className='large-brand-footer'>
                    <div className='large-brand-img-container'>
                        <img className='large-brand-logo' src="/img/untitled-40-23.png" alt="Lux And Co" />
                    </div>
                    <p>Discover The Collection</p>
                </div>
            </div>
        </div>
    )
}


const products = [
    {
        'brand': 'Patek Phillipe',
        'img': '/img/group-50.png',
        '_id': process.env.REACT_APP_BRAND_PETEK_ID
    },
    {
        'brand': 'Richard Mille',
        'img': '/img/group-51.png',
        '_id': process.env.REACT_APP_BRAND_RM_ID
    },
    {
        'brand': 'Rolex',
        'img': '/img/Rolex-Brand-Image.png',
        '_id': process.env.REACT_APP_BRAND_ROLEX_ID
    },
    {
        'brand': 'Audemars Piguet',
        'img': '/img/AP-Brand-Image.png',
        '_id': process.env.REACT_APP_BRAND_ROLEX_ID
    },
];

const SliderBrands = (props) => {
    const prevRefBrand = useRef(null);
    const nextRefBrand = useRef(null);
    const [isBeginningBrand, setIsBeginningBrand] = useState(true);
    const [isEndBrand, setIsEndBrand] = useState(false);

    useEffect(() => {
        const swiperInstance = document.querySelector('.myBrandLargeSlider').swiper;
        if (swiperInstance) {
            console.log('Swiper instance initialized:', swiperInstance);

            swiperInstance.params.navigation.prevEl = prevRefBrand.current;
            swiperInstance.params.navigation.nextEl = nextRefBrand.current;

            // Destroy and reinitialize navigation to avoid duplication
            swiperInstance.navigation.destroy();
            swiperInstance.navigation.init();
            swiperInstance.navigation.update();

            // Event listener for slide change
            swiperInstance.on('slideChange', () => {
                setIsBeginningBrand(swiperInstance.isBeginning);
                setIsEndBrand(swiperInstance.isEnd);
            });

            // Event listener for reaching the end
            swiperInstance.on('reachEnd', () => {
                setIsEndBrand(true);
            });

            // Event listener for leaving the end or beginning
            swiperInstance.on('fromEdge', () => {
                setIsBeginningBrand(swiperInstance.isBeginning);
                setIsEndBrand(swiperInstance.isEnd);
            });

            // Initial check for swiper state
            setIsBeginningBrand(swiperInstance.isBeginning);
            setIsEndBrand(swiperInstance.isEnd);
        }
    }, []);

    return (
        <div>
            <div className="swiper-nav-buttons">
                <img 
                    ref={prevRefBrand} 
                    src="/img/group-24-4.png" 
                    alt="Previous" 
                    className="large-slider-arrow-left" 
                    style={{
                        height: '60px', 
                        width: '60px', 
                        transform: 'rotate(180deg)', 
                        display: isBeginningBrand ? 'none' : 'block',
                    }}
                />
                <img 
                    ref={nextRefBrand} 
                    src="/img/group-24-4.png" 
                    alt="Next" 
                    className="large-slider-arrow-right" 
                    style={{
                        height: '60px', 
                        width: '60px', 
                        display: isEndBrand ? 'none' : 'block',
                    }}
                />
            </div>
            <Swiper
                watchSlidesProgress={true}
                spaceBetween={10}
                className="myBrandLargeSlider"
                modules={[Navigation]}
                onInit={(swiper) => {
                    swiper.params.navigation.prevEl = prevRefBrand.current;
                    swiper.params.navigation.nextEl = nextRefBrand.current;
                    swiper.navigation.init();
                    swiper.navigation.update();

                    // Initial state check
                    setIsBeginningBrand(swiper.isBeginning);
                    setIsEndBrand(swiper.isEnd);
                }}
                breakpoints={{
                    100: {
                        slidesPerView: 1.05,
                    },
                    376: {
                        slidesPerView: 1.185,
                    },
                    880: {
                        slidesPerView: 1.42,
                    },
                    1000: {
                        slidesPerView: 1.42,
                    },
                    1700: {
                        slidesPerView: 2.5,
                    },

                }}
            >
                {products.map((product, index) => (
                    <SwiperSlide key={index} className='swiper-slider-largebrand-container'>
                        <Brand productInfo={product} className='swiper-largeslider-brand' onClose={props.onClose}/>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}
 
export default SliderBrands;