import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './index.css';
import HomeDesktop from './Home.jsx';
import SellConsign from './SellConsign.jsx';
import { Catalouge } from './Catalouge.jsx';
import AdminSignin from './components/AdminSignin.jsx';
import AdminOverview from './components/AdminOverview.js';
import ProductPage from './components/ProductPage';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './components/AuthContext.js';
import AboutPage from './components/AboutPage.js'
import AdminProductPage from './components/AdminProductPage.js';
import ImageUpload from './components/ImageUpload.js'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <Router>
      <Routes>
        <Route path="/" element={<HomeDesktop/>} />
        <Route path="/luxandco" element={<HomeDesktop/>} />
        <Route path="/sellconsign" element={<SellConsign/>} />
        <Route path="/catalog" element={<Catalouge/>} />
        <Route path="/dashboardsignin" element={<AdminSignin/>} />
        <Route path="/dashboardinventory" element={<AdminOverview/>}/>
        <Route path="/testupload" element={<ImageUpload/>}/>
        <Route path="/:id" element={<ProductPage/>}/>
        <Route path="/about" element={<AboutPage/>}/>
      </Routes>
    </Router>
    </AuthProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
